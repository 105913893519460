/* @flow */
import _ from 'lodash';
import format from 'string-template';
import type { SuperscoreVariableTypesT } from 'symptoTypes/sympto-provider-creation-types';

export const SUPERSCORE_VARIABLE_REGEX: RegExp = /\{([0-9a-zA-Z_]+)\}/g;
export const SUPERSCORE_VARIABLE_REGEX_STR: string = '{([0-9a-zA-Z_]+)}';

export const findUndefinedVariables = (
  targetString: string,
  variables: Array<string>
): { status: 'error', undefinedVariable: string } | { status: 'success' } => {
  const includedVariables = [
    ...targetString.matchAll(SUPERSCORE_VARIABLE_REGEX),
  ].map(([, variable]) => variable);
  const undefinedVariable = includedVariables.find(
    (variable) => !variables.includes(variable)
  );
  return undefinedVariable
    ? { status: 'error', undefinedVariable }
    : { status: 'success' };
};

export const fillMessageWithVariables = ({
  message,
  variables,
}: {
  message: string,
  variables:
    | { [variableName: string]: SuperscoreVariableTypesT }
    | { [variableName: string]: string },
}): string => {
  const undefinedVariable = findUndefinedVariables(message, _.keys(variables));
  if (undefinedVariable.status === 'error') {
    throw new Error(
      `Undefined variable: ${undefinedVariable.undefinedVariable}`
    );
  }

  return format(message, variables);
};
