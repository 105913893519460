/* @flow */
import type { CancelToken } from 'axios';
import consts from 'consts';
import pDebounce from 'p-debounce';
import type { ProviderCreationT } from 'symptoTypes/admin';
import type { StatusId } from 'symptoTypes/clinicStatus';
import type { PatientTvId } from 'symptoTypes/opaques';
import type {
  PatientCreationDataT,
  PriorityOrderingT,
  ResponseId,
  SettingsOptions,
} from 'symptoTypes/patient';
import type {
  FetchedPatientAtttributeDataT,
  PatientAttributeValueT,
} from 'symptoTypes/patientAttributes';
import type {
  AppointmentCreationPayloadT,
  BasePatientData,
  ConsentT,
  CSVMappingT,
  DateInMillis,
  DoctorTvId,
  GenericSurveyId,
  GroupId,
  LanguagesT,
  NurseTvId,
  PatientAttributeDefaultValues,
  PatientIdentifierValueT,
  PatientSurveyId,
  ProviderAvailabilityDays,
  ProviderNotifTypes,
  ProviderUpdateDataT,
  RecurringSurveyId,
  WearableFieldsT,
  WebhookPayloadTypesT,
} from 'symptoTypes/provider';
import type { ErrorResponse } from 'symptoTypes/responses';
import type { QuestionId, SurveyResponseT } from 'symptoTypes/surveyResponses';
import type {
  AnyQuestionDataT,
  CampaignAutoEnrollGroupT,
  CampaignItemConfigT,
  CampaignTriggers,
  ChatbotTaskT,
  ExerciseItemT,
  GenericSurveyUploadedT,
  InstrumentTypes,
  InstrumentTypesMappingT,
  InstrumentVariableDataT,
  ProviderSurveyForGenericT,
  SuperscoreVariableTypesT,
  SurveyStartDates,
  TriagePageT,
} from 'symptoTypes/sympto-provider-creation-types';
import type { ClinicSettingsDataT, RememberMeCookieT } from 'symptoTypes/users';
import type {
  DeleteResponse,
  GetRequest,
  GetResponse,
  PostResponse,
  PutRequest,
  PutResponse,
} from 'symptoTypes/utils';
import Auth from 'utils/Auth';

import apis from '../apis';
import { setCurrentUser } from '../utils/LogTrace';
import type { PrefilledSurveyT } from './prefilledSurveys';
import {
  fetchPrefilledSurvey,
  fetchPrefilledSurveyWithId,
} from './prefilledSurveys';
import type {
  SavePushNotificationTokenResponse,
  UpdateCancerTypeResponse,
} from './types';
import { STATUS_OK } from './types';

const searchMessages = async ({
  patientTvId,
  query,
}: {
  query: string,
  patientTvId: string,
}): Promise<
  ErrorResponse | GetResponse<'/provider/messaging/:patientTvId/search'>
> =>
  apis.getT<'/provider/messaging/:patientTvId/search'>(
    '/provider/messaging/:patientTvId/search',
    {
      patientTvId,
    },
    {
      query,
    }
  );

const pendingSurveysJWT = async (
  surveyJwtCode: string
): Promise<ErrorResponse | GetResponse<'/patients/surveys'>> =>
  apis.getT<'/patients/surveys'>(
    '/patients/surveys',
    {},
    {
      surveyJwtCode,
    }
  );

const changePatientUnreadStatus = async ({
  patientTvId,
  isUnread,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
  isUnread: boolean,
}): Promise<
  PostResponse<'/provider/messaging/unread/:patientTvId'> | ErrorResponse
> =>
  apis.postT<'/provider/messaging/unread/:patientTvId'>(
    '/provider/messaging/unread/:patientTvId',
    { patientTvId },
    { isUnread },
    cancelToken
  );

const fetchMessages = async ({
  patientId,
  limit,
  before,
  after,
  threadId,
  preserveUnreadStatus,
  cancelToken,
}: {
  patientId: PatientTvId,
  limit: ?number,
  cancelToken?: CancelToken,
  before: ?number,
  after: ?number,
  preserveUnreadStatus?: boolean,
  threadId: ?string,
}): Promise<ErrorResponse | GetResponse<'/provider/messaging/:patientTvId'>> =>
  apis.getT<'/provider/messaging/:patientTvId'>(
    '/provider/messaging/:patientTvId',
    {
      patientTvId: patientId,
    },
    {
      limit,
      before,
      after,
      threadId,
      preserveUnreadStatus,
    },
    cancelToken
  );

const updateProviderLanguage = async ({
  language,
}: {
  language: LanguagesT,
}): Promise<PostResponse<'/providers/language/update'> | ErrorResponse> =>
  apis.postT<'/providers/language/update'>(
    '/providers/language/update',
    {},
    { language }
  );

const updateProvider = async ({
  data,
  providerTvId,
}: {|
  data:
    | {|
        ...ProviderUpdateDataT,
        phoneNumber: null | string,
        email: string,
      |}
    | {|
        ...ProviderUpdateDataT,
        phoneNumber: string,
        email: null | string,
      |},
  providerTvId: DoctorTvId | NurseTvId,
|}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/providers/:providerTvId/update'>
> =>
  apis.postT<'/clinicAdmin/providers/:providerTvId/update'>(
    '/clinicAdmin/providers/:providerTvId/update',
    {
      providerTvId,
    },
    data
  );

const postConsent = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<ErrorResponse | PostResponse<'/providers/consent'>> =>
  apis.postT<'/providers/consent'>('/providers/consent', {}, { patientTvId });

const getClinicAPISettings = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/clinic/api/settings'>> =>
  apis.getT<'/clinicAdmin/clinic/api/settings'>(
    '/clinicAdmin/clinic/api/settings',
    {},
    {},
    cancelToken
  );

const setClinicAPISettings = async ({
  enableAPI,
  enableClientSidePatientCreation,
}: {
  enableAPI: boolean,
  enableClientSidePatientCreation: boolean,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinic/api/settings'>> =>
  apis.postT<'/clinicAdmin/clinic/api/settings'>(
    '/clinicAdmin/clinic/api/settings',
    {},
    { enableClientSidePatientCreation, enableAPI }
  );

const getClinicSettings = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/clinic/settings'>> =>
  apis.getT<'/clinicAdmin/clinic/settings'>(
    '/clinicAdmin/clinic/settings',
    {},
    {},
    cancelToken
  );

const setClinicSettings = async (
  data: $Diff<ClinicSettingsDataT, { type: any }>
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinic/settings'>> =>
  apis.postT<'/clinicAdmin/clinic/settings'>(
    '/clinicAdmin/clinic/settings',
    {},
    data
  );

const sendMessage = async ({
  patientId,
  message,
  threadId,
  includeSignature,
}: {
  patientId: PatientTvId,
  message: string,
  includeSignature: boolean,
  threadId: ?string,
}): Promise<ErrorResponse | PostResponse<'/provider/messaging/:patientTvId'>> =>
  apis.postT<'/provider/messaging/:patientTvId'>(
    '/provider/messaging/:patientTvId',
    {
      patientTvId: patientId,
    },
    { message, includeSignature, threadId }
  );

const getAllGroupsInClinic = ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<GetResponse<'/providers/groups/clinic'> | ErrorResponse> =>
  apis.getT<'/providers/groups/clinic'>(
    '/providers/groups/clinic',
    {},
    {},
    cancelToken
  );

export const getMetadataForAllProvidersInClinic = ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<GetResponse<'/providers/clinic/metadata'> | ErrorResponse> =>
  apis.getT<'/providers/clinic/metadata'>(
    '/providers/clinic/metadata',
    {},
    {},
    cancelToken
  );

export const updateMetadataForProvider = ({
  cancelToken,
  providerTvId,
  metadata,
}: {
  cancelToken?: CancelToken,
  providerTvId: string,
  metadata: { [string]: string },
}): Promise<
  PostResponse<'/clinicAdmin/providers/:providerTvId/metadata'> | ErrorResponse
> =>
  apis.postT<'/clinicAdmin/providers/:providerTvId/metadata'>(
    '/clinicAdmin/providers/:providerTvId/metadata',
    {
      providerTvId,
    },
    {
      metadata,
    },
    cancelToken
  );

async function getGroups({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/groups'>> {
  return apis.getT<'/providers/groups'>(
    '/providers/groups',
    {},
    {},
    cancelToken
  );
}

async function queryMedicines({
  query,
  cancelToken,
}: {
  query: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/patients/medicines'>> {
  return apis.getT<'/patients/medicines'>(
    '/patients/medicines',
    {},
    { query, limit: consts.PAGE_LIMIT },
    cancelToken
  );
}

async function login(
  username: string,
  password: string,
  rememberMe: boolean
): Promise<ErrorResponse | PostResponse<'/users/login'>> {
  const loginData = await apis.postT<'/users/login'>(
    '/users/login',
    {},
    {
      username,
      rememberMe,
      password,
    }
  );
  if (loginData.Status === STATUS_OK) {
    Auth.onLogin();
  }
  return loginData;
}

async function logout(): Promise<
  ErrorResponse | PostResponse<'/users/logout'>
> {
  const logoutData = apis.postT<'/users/logout'>(
    '/users/logout',
    {},
    Object.freeze({})
  );
  setCurrentUser(null);
  Auth.onLogout();
  return logoutData;
}

const imageDownload = async ({
  imageID,
  patientSurveyId,
  surveyJwtCode,
  pdfShortCodeImageToken,
}: {
  imageID: string,
  patientSurveyId: string,
  surveyJwtCode?: string,
  pdfShortCodeImageToken?: ?string,
}): Promise<ErrorResponse | GetResponse<'/users/patientSurvey/images'>> =>
  apis.getT<'/users/patientSurvey/images'>(
    '/users/patientSurvey/images',
    {},
    {
      patientSurveyId,
      imageID,
      surveyJwtCode,
      pdfShortCodeImageToken,
    }
  );

const isLoggedIn = async (
  cancelToken?: CancelToken
): Promise<
  | GetResponse<'/users/loggedIn'>
  | ErrorResponse
  | {
      Status: 'Error',
      Response: {
        ...RememberMeCookieT,
      },
      // $FlowFixMe
    }
> => apis.getT<'/users/loggedIn'>('/users/loggedIn', {}, {}, cancelToken);

const getClinicMetadataFromShortName = ({
  shortName,
}: {
  shortName: string,
}): Promise<GetResponse<'/clinics/:shortName/metadata'> | ErrorResponse> =>
  apis.getT<'/clinics/:shortName/metadata'>(
    '/clinics/:shortName/metadata',
    { shortName },
    {}
  );

async function fetchSurveyWithCode({
  surveyCode,
  cancelToken,
}: {
  surveyCode: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | PostResponse<'/patients/surveys/code/:surveyCode/fetch'>
> {
  return apis.postT<'/patients/surveys/code/:surveyCode/fetch'>(
    '/patients/surveys/code/:surveyCode/fetch',
    { surveyCode },
    {},
    cancelToken
  );
}

const refetchSurveyCode = ({
  surveyJwtCode,
  cancelToken,
}: {
  surveyJwtCode: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | PostResponse<'/patients/survey/code/:surveyJwtCode/refetch'>
> =>
  apis.postT<'/patients/survey/code/:surveyJwtCode/refetch'>(
    '/patients/survey/code/:surveyJwtCode/refetch',
    { surveyJwtCode },
    {},
    cancelToken
  );

async function submitSurveyResponseWithCode({
  surveyCode,
  isComplete,
  ...surveyData
}: {
  surveyCode: string,
  response: ?SurveyResponseT,
  responseId: ResponseId,
  isComplete: boolean,
  updatedQuestions: Array<QuestionId>,
}): Promise<
  ErrorResponse | PostResponse<'/patients/surveys/code/:surveyCode'>
> {
  return apis.postT<'/patients/surveys/code/:surveyCode'>(
    '/patients/surveys/code/:surveyCode',
    { surveyCode },
    {
      ...surveyData,
      responseCompletion: isComplete ? 'Full' : 'Partial',
    }
  );
}

async function uploadImage(
  file: File,
  surveyJwtCode: string,
  onProgress: (number) => void
): Promise<
  | ErrorResponse
  | {
      Response: { dateUploaded: string, imageURL: string, key: string },
      Status: 'OK',
    }
> {
  const form = new FormData();
  form.append('file', file);
  form.append('surveyJwtCode', surveyJwtCode);
  // XXX we don't have good typing for forms (Prithvi)
  return apis.post<
    *,
    {
      Status: 'OK',
      Response: { key: string, imageURL: string, dateUploaded: string },
    }
  >('/users/patientSurvey/images', form, onProgress);
}

async function vitalAPIConnect({
  surveyJwtCode,
}: {
  surveyJwtCode: string,
}): Promise<ErrorResponse | GetResponse<'/patients/wearables/connect'>> {
  return apis.getT<'/patients/wearables/connect'>(
    '/patients/wearables/connect',
    {},
    { surveyJwtCode }
  );
}

const getVitalConnectors = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/vitalAttributes'>
> =>
  apis.getT<'/clinicAdmin/clinic/vitalAttributes'>(
    '/clinicAdmin/clinic/vitalAttributes',
    {},
    {},
    cancelToken
  );

const addVitalConnectors = async ({
  patientAttributeId,
  field,
}: {
  patientAttributeId: string,
  field: WearableFieldsT,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/vitalAttributes'>
> =>
  apis.postT<'/clinicAdmin/clinic/vitalAttributes'>(
    '/clinicAdmin/clinic/vitalAttributes',
    {},
    { patientAttributeId, field }
  );

const deleteVitalConnectors = async ({
  id,
}: {
  id: string,
}): Promise<
  ErrorResponse | DeleteResponse<'/clinicAdmin/clinic/vitalAttributes'>
> =>
  apis.deleteT<'/clinicAdmin/clinic/vitalAttributes'>(
    '/clinicAdmin/clinic/vitalAttributes',
    {},
    { id }
  );

async function getInstrumentFileForSurvey({
  instrumentFileId,
  surveyJwtCode,
}: {
  instrumentFileId: string,
  surveyJwtCode: string,
}): Promise<
  ErrorResponse | GetResponse<'/patients/surveys/file/:instrumentFileId'>
> {
  return apis.getT<'/patients/surveys/file/:instrumentFileId'>(
    '/patients/surveys/file/:instrumentFileId',
    { instrumentFileId },
    { surveyJwtCode }
  );
}

async function validateSurveyInput({
  superscoreVariableId,
  surveyJwtCode,
  inputValue,
  comparisionType,
}: {
  surveyJwtCode: string,
  superscoreVariableId: string,
  inputValue: SuperscoreVariableTypesT,
  comparisionType: 'equal-to-hashed' | 'equal-to',
}): Promise<ErrorResponse | PostResponse<'/patients/surveys/input/validate'>> {
  return apis.postT<'/patients/surveys/input/validate'>(
    '/patients/surveys/input/validate',
    {},
    {
      surveyJwtCode,
      superscoreVariableId,
      inputValue,
      comparisionType,
    }
  );
}
const fetchFreeformAppointmentMetadataForSurvey = async ({
  questionId,
  surveyJwtCode,
  cancelToken,
}: {
  surveyJwtCode: string,
  questionId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/patients/surveys/freeformappt/metadata'>
> =>
  apis.getT<'/patients/surveys/freeformappt/metadata'>(
    '/patients/surveys/freeformappt/metadata',
    {},
    { surveyJwtCode, questionId },
    cancelToken
  );

export const fetchPatientAttributesForSurvey = async ({
  cancelToken,
  patientAttributeId,
  surveyJwtCode,
}: {
  cancelToken?: CancelToken,
  patientAttributeId: string,
  surveyJwtCode: string,
}): Promise<ErrorResponse | GetResponse<'/patients/surveys/attributes'>> =>
  apis.getT<'/patients/surveys/attributes'>(
    '/patients/surveys/attributes',
    {},
    {
      patientAttributeId,
      surveyJwtCode,
    },
    cancelToken
  );

export const fetchAppointmentsForSurvey = async ({
  startDate,
  endDate,
  surveyJwtCode,
  cancelToken,
}: {
  startDate: DateInMillis,
  endDate: DateInMillis,
  surveyJwtCode: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/patients/surveys/appointments'>> =>
  apis.getT<'/patients/surveys/appointments'>(
    '/patients/surveys/appointments',
    {},
    { startDate, endDate, surveyJwtCode },
    cancelToken
  );

export const fetchDefaultInstruments = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/clinic/defaultInstruments'>
> =>
  apis.getT<'/providers/clinic/defaultInstruments'>(
    '/providers/clinic/defaultInstruments',
    {},
    {},
    cancelToken
  );

const addDefaultInstruments = async (
  data: PutRequest<'/clinicAdmin/clinic/defaultInstruments'>
): Promise<
  ErrorResponse | PutResponse<'/clinicAdmin/clinic/defaultInstruments'>
> =>
  apis.putT<'/clinicAdmin/clinic/defaultInstruments'>(
    '/clinicAdmin/clinic/defaultInstruments',
    {},
    data
  );

const deleteDefaultInstruments = async ({
  defaultInstrumentId,
}: {
  defaultInstrumentId: string,
}): Promise<
  ErrorResponse | DeleteResponse<'/clinicAdmin/clinic/defaultInstruments'>
> =>
  apis.deleteT<'/clinicAdmin/clinic/defaultInstruments'>(
    '/clinicAdmin/clinic/defaultInstruments',
    {},
    { id: defaultInstrumentId }
  );

const getDefaultInstrumentsForClinicAdmin = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/defaultInstruments'>
> =>
  apis.getT<'/clinicAdmin/clinic/defaultInstruments'>(
    '/clinicAdmin/clinic/defaultInstruments',
    {},
    {},
    cancelToken
  );

export const createWebhook = async (data: {
  payloadType: WebhookPayloadTypesT,
  url: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/webhooks'>> =>
  apis.postT<'/clinicAdmin/webhooks'>('/clinicAdmin/webhooks', {}, data);

export const deleteWebhook = async (data: {
  id: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/webhooks'>> =>
  apis.deleteT<'/clinicAdmin/webhooks'>('/clinicAdmin/webhooks', {}, data);

export const fetchWebhooks = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/webhooks'>> =>
  apis.getT<'/clinicAdmin/webhooks'>(
    '/clinicAdmin/webhooks',
    {},
    {},
    cancelToken
  );

export const getInstrumentOrClinicFileURL = async ({
  cancelToken,
  fileData,
}: {
  fileData: GetRequest<'/providers/file/signedURL'>,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/file/signedURL'>> =>
  apis.getT<'/providers/file/signedURL'>(
    '/providers/file/signedURL',
    {},
    fileData,
    cancelToken
  );

export const getSignedFileURL = async ({
  useUnoptimizedURL,
  clinicFileId,
  cancelToken,
}: {
  clinicFileId: string,
  useUnoptimizedURL: boolean,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/file/signedURL'>> =>
  apis.getT<'/providers/file/signedURL'>(
    '/providers/file/signedURL',
    {},
    { useUnoptimizedURL, clinicFileId, type: 'Clinic File' },
    cancelToken
  );

const getQueuedAppointments = async ({
  cancelToken,
  ...ptIdentifier
}: {
  ...PatientIdentifierValueT,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/queuedAppointments'>
> =>
  apis.getT<'/providers/patients/queuedAppointments'>(
    '/providers/patients/queuedAppointments',
    {},
    ptIdentifier,
    cancelToken
  );

async function getClinicFile({
  clinicFileId,
  surveyJwtCode,
}: {
  clinicFileId: string,
  surveyJwtCode?: ?string,
}): Promise<ErrorResponse | GetResponse<'/users/file/:clinicFileId'>> {
  return apis.getT<'/users/file/:clinicFileId'>(
    '/users/file/:clinicFileId',
    { clinicFileId },
    surveyJwtCode && surveyJwtCode.trim().length > 0 ? { surveyJwtCode } : {}
  );
}

async function uploadFileForClinic(
  file: File,
  fileName: string,
  onProgress: (number) => void,
  isPublic?: boolean
): Promise<
  ErrorResponse | { Response: { clinicFileId: string }, Status: 'OK' }
> {
  const form = new FormData();
  form.append('file', file);
  form.append('isPublic', String(isPublic || false));
  form.append('fileName', fileName);
  // XXX we don't have good typing for forms (Prithvi)
  return apis.post<*, { Status: 'OK', Response: { clinicFileId: string } }>(
    '/providers/instrument/file',
    form,
    onProgress
  );
}

const uploadTranscriptionForClinic = async ({
  file,
  surveyJwtCode,
  genericQuestionId,
  onProgress,
}: {
  file: File,
  surveyJwtCode: string,
  genericQuestionId: string,
  onProgress: (number) => void,
}): Promise<
  ErrorResponse | { Response: { transcript: string }, Status: 'OK' }
> => {
  const form = new FormData();
  form.append('file', file);
  form.append('genericQuestionId', genericQuestionId);
  form.append('surveyJwtCode', surveyJwtCode);
  // XXX we don't have good typing for forms (Prithvi)
  return apis.post<*, { Status: 'OK', Response: { transcript: string } }>(
    '/ai/deepgram/transcribe',
    form,
    onProgress
  );
};

async function uploadFile(
  file: File,
  patientTvId: PatientTvId,
  message: string,
  fileName: string,
  threadId: ?string,
  onProgress: (number) => void
): Promise<ErrorResponse | $Exact<{| s3key: string |}>> {
  const form = new FormData();
  form.append('file', file);
  form.append('patientTvId', patientTvId);
  form.append('message', message);
  form.append('fileName', fileName);
  if (threadId) {
    form.append('threadId', threadId);
  }
  // XXX we don't have good typing for forms (Prithvi)
  return apis.post<*, {| s3key: string |}>('/providers/file', form, onProgress);
}

async function uploadFileForPatient(
  file: File,
  message: string,
  fileName: string,
  threadId: ?string,
  onProgress: (number) => void
): Promise<ErrorResponse | { s3key: string }> {
  const form = new FormData();
  form.append('file', file);
  form.append('message', message);
  form.append('fileName', fileName);
  if (threadId) {
    form.append('threadId', threadId);
  }
  // XXX we don't have good typing for forms (Prithvi)
  return apis.post<*, { s3key: string }>('/patients/file', form, onProgress);
}

const fetchAppointments = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/appointmentTags'>> =>
  apis.getT<'/providers/appointmentTags'>(
    '/providers/appointmentTags',
    {},
    {},
    cancelToken
  );

const deprecatePatient = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | DeleteResponse<'/providers/patients/:patientTvId'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId'>(
    '/providers/patients/:patientTvId',
    { patientTvId },
    {}
  );

const enablePatient = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/patients/:patientTvId/enable'>
> =>
  apis.postT<'/clinicAdmin/patients/:patientTvId/enable'>(
    '/clinicAdmin/patients/:patientTvId/enable',
    { patientTvId },
    {}
  );

export const fetchRateToken = async (
  cancelToken: CancelToken
): Promise<ErrorResponse | GetResponse<'/authorization/rateToken'>> =>
  apis.getT<'/authorization/rateToken'>(
    '/authorization/rateToken',
    {},
    {},
    cancelToken
  );

export const fetchAIAPIToken = async (): Promise<
  ErrorResponse | PostResponse<'/authorization/authenticatedUser'>
> =>
  apis.postT<'/authorization/authenticatedUser'>(
    '/authorization/authenticatedUser',
    {},
    {}
  );

const saveSystemPhoneNumberCallQueue = async ({
  priorities,
  systemPhoneNumberId,
}: {
  priorities: Array<string>,
  systemPhoneNumberId: string,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/phoneNumbers/callQueue'>
> =>
  apis.postT<'/clinicAdmin/clinic/phoneNumbers/callQueue'>(
    '/clinicAdmin/clinic/phoneNumbers/callQueue',
    {},
    { priorities, systemPhoneNumberId }
  );

const getSystemPhoneNumberCallQueue = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/phoneNumbers/callQueue'>
> =>
  apis.getT<'/clinicAdmin/clinic/phoneNumbers/callQueue'>(
    '/clinicAdmin/clinic/phoneNumbers/callQueue',
    {},
    {},
    cancelToken
  );

export const fetchPatientAttributeValues = async ({
  statusFilters,
  groupFilters,
  cancelToken,
}: {|
  statusFilters: ?Array<StatusId>,
  cancelToken?: ?CancelToken,
  groupFilters: ?Array<GroupId>,
|}): Promise<
  ErrorResponse | GetResponse<'/providers/clinic/attributes/values'>
> =>
  apis.getT<'/providers/clinic/attributes/values'>(
    '/providers/clinic/attributes/values',
    {},
    {
      groupFilters,
      statusFilters,
    },
    cancelToken
  );

const bulkCreatePatients = async (
  patients: Array<{ ...PatientCreationDataT, indexId: string }>,
  uploadId: string
): Promise<ErrorResponse | PostResponse<'/providers/patients/bulk'>> =>
  apis.postT<'/providers/patients/bulk'>(
    '/providers/patients/bulk',
    {},
    { uploadId, patients }
  );

const createPatientAccountFromProvider = async (
  data: PatientCreationDataT
): Promise<ErrorResponse | PostResponse<'/providers/patients'>> =>
  apis.postT<'/providers/patients'>('/providers/patients', {}, data, null);

async function sendPasswordResetEmail({
  username,
}: {
  username: string,
}): Promise<ErrorResponse | PostResponse<'/users/password/forgot'>> {
  return apis.postT<'/users/password/forgot'>(
    '/users/password/forgot',
    {},
    { username }
  );
}

async function resetPassword(
  userId: string,
  httpAuth: string,
  password: string
): Promise<ErrorResponse | PostResponse<'/users/password/reset'>> {
  return apis.postT<'/users/password/reset'>(
    '/users/password/reset',
    {},
    { userId, httpAuth, password }
  );
}

async function changePassword(
  prevPassword: string,
  newPassword: string
): Promise<ErrorResponse | PostResponse<'/users/password/change'>> {
  return apis.postT<'/users/password/change'>(
    '/users/password/change',
    {},
    { prevPassword, newPassword }
  );
}

async function getSettings(): Promise<
  ErrorResponse | GetResponse<'/patients/settings'>
> {
  return apis.getT<'/patients/settings'>(
    '/patients/settings',
    {},
    Object.freeze({})
  );
}

async function setSettings({
  NotificationType,
  language,
}: SettingsOptions): Promise<
  ErrorResponse | PostResponse<'/patients/settings'>
> {
  return apis.postT<'/patients/settings'>(
    '/patients/settings',
    {},
    { settings: { NotificationType, language } }
  );
}

const removePatientFromInstrument = async (
  patientTvId: PatientTvId,
  recurringSurveyId: string
): Promise<
  | ErrorResponse
  | DeleteResponse<'/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId'>(
    '/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId',
    { patientTvId, recurringSurveyId },
    {}
  );

async function deprecateRecurringSurvey(
  recurringSurveyId: string
): Promise<
  | ErrorResponse
  | DeleteResponse<'/providers/surveys/recurringSurvey/:recurringSurveyId'>
> {
  return apis.deleteT<'/providers/surveys/recurringSurvey/:recurringSurveyId'>(
    '/providers/surveys/recurringSurvey/:recurringSurveyId',
    { recurringSurveyId },
    {}
  );
}

const fetchVideoLogs = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: PatientSurveyId,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patientSurvey/:patientSurveyId/video/logs'>
> =>
  apis.getT<'/providers/patientSurvey/:patientSurveyId/video/logs'>(
    '/providers/patientSurvey/:patientSurveyId/video/logs',
    { patientSurveyId },
    {},
    cancelToken
  );

const createVideoCall = async ({
  threadId,
  patientTvId,
  startDatePreference,
}: {
  patientTvId: PatientTvId,
  threadId: ?string,
  startDatePreference: SurveyStartDates,
}): Promise<ErrorResponse | PostResponse<'/providers/video'>> =>
  apis.postT<'/providers/video'>(
    '/providers/video',
    {},
    {
      patientTvId,
      threadId,
      startDatePreference,
    }
  );

async function updateCancerType(
  type: ?string
): Promise<ErrorResponse | UpdateCancerTypeResponse> {
  return apis.post('/patients/update/type', { type });
}

async function savePushNotificationToken(
  pushToken: string
): Promise<ErrorResponse | SavePushNotificationTokenResponse> {
  return apis.post('/patients/update/push', { pushToken });
}

const getProviderMetadata = async (): Promise<
  ErrorResponse | GetResponse<'/providers/metadata'>
> => apis.getT<'/providers/metadata'>('/providers/metadata', {}, {});

const createSurvey = (
  survey: GenericSurveyUploadedT
): Promise<ErrorResponse | PostResponse<'/providers/surveys'>> =>
  apis.postT<'/providers/surveys'>('/providers/surveys', {}, { survey });

const validateAuthCode = (
  userAuthToken: string
): Promise<ErrorResponse | PostResponse<'/webhook'>> =>
  apis.postT<'/webhook'>('/webhook', {}, { userAuthToken });

const updateGroupName = ({
  groupId,
  groupName,
}: {
  groupId: string,
  groupName: string,
}): Promise<ErrorResponse | PutResponse<'/clinicAdmin/groups/:groupId'>> =>
  apis.putT<'/clinicAdmin/groups/:groupId'>(
    '/clinicAdmin/groups/:groupId',
    { groupId },
    { groupName }
  );

const createProviderForClinicAdmin = (
  data: ProviderCreationT
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/providers'>> =>
  apis.postT<'/clinicAdmin/providers'>('/clinicAdmin/providers', {}, data);

const dismissAlert = (
  alertId: string
): Promise<ErrorResponse | PutResponse<'/providers/alerts/:alertId'>> =>
  apis.putT<'/providers/alerts/:alertId'>(
    '/providers/alerts/:alertId',
    { alertId },
    {}
  );

const updatePatient = async ({
  patientTvId,
  patientData,
}: {|
  patientTvId: PatientTvId,
  patientData:
    | {
        ...BasePatientData,
        email: string,
        phoneNumber: null | string,
      }
    | {
        ...BasePatientData,
        email: null | string,
        phoneNumber: string,
      },
|}): Promise<
  ErrorResponse | PostResponse<'/providers/patients/:patientTvId/update'>
> =>
  apis.postT<'/providers/patients/:patientTvId/update'>(
    '/providers/patients/:patientTvId/update',
    { patientTvId },
    {
      ...patientData,
      patientAttributes: null,
    }
  );

const toggleSupportRoleAsAdmin = async ({
  enabled,
  clinicId,
}: {
  enabled: boolean,
  clinicId: string,
}): Promise<ErrorResponse | PostResponse<'/admin/support/toggle'>> =>
  apis.postT<'/admin/support/toggle'>(
    '/admin/support/toggle',
    {},
    { enabled, clinicId }
  );

const toggleSupportRole = async ({
  enabled,
}: {
  enabled: boolean,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/support/toggle'>> =>
  apis.postT<'/clinicAdmin/support/toggle'>(
    '/clinicAdmin/support/toggle',
    {},
    { enabled }
  );

export const diffResponses = async ({
  newResponse,
  patientSurveyId,
}: {
  newResponse: SurveyResponseT,
  patientSurveyId: PatientSurveyId,
}): Promise<
  ErrorResponse | PostResponse<'/providers/responses/:patientSurveyId/diff'>
> =>
  apis.postT<'/providers/responses/:patientSurveyId/diff'>(
    '/providers/responses/:patientSurveyId/diff',
    { patientSurveyId },
    { newResponse }
  );

const fetchResponses = async ({
  patientSurveyIds,
  patientTvId,
  cancelToken,
}: {
  patientSurveyIds: Array<PatientSurveyId>,
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/responses'>> =>
  apis.getT<'/providers/responses'>(
    '/providers/responses',
    {},
    { patientSurveyIds, patientTvId },
    cancelToken
  );

const fetchResponseWithOneTimeAccessShortCode = async ({
  patientTvId,
  pdfDownloadShortCode,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  pdfDownloadShortCode: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/users/patientSurvey/shortCode'>> =>
  apis.getT<'/users/patientSurvey/shortCode'>(
    '/users/patientSurvey/shortCode',
    {},
    { pdfDownloadShortCode, patientTvId },
    cancelToken
  );

const fetchGlobalCSVResponses = async ({
  genericSurveyId,
  cancelToken,
}: {
  genericSurveyId: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/responses/global/csv'>> =>
  apis.getT<'/clinicAdmin/responses/global/csv'>(
    '/clinicAdmin/responses/global/csv',
    {},
    { genericSurveyId },
    cancelToken
  );

const fetchCSVResponses = async ({
  patientSurveyIds,
  patientTvId,
  cancelToken,
}: {
  patientSurveyIds: Array<PatientSurveyId>,
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/responses/csv'>> =>
  apis.getT<'/providers/responses/csv'>(
    '/providers/responses/csv',
    {},
    { patientSurveyIds, patientTvId, type: 'Patient Survey Ids' },
    cancelToken
  );

const fetchResponseById = async ({
  patientSurveyId,
  patientTvId,
  cancelToken,
}: {
  patientSurveyId: string,
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/responses/:patientSurveyId'>
> =>
  apis.getT<'/providers/responses/:patientSurveyId'>(
    '/providers/responses/:patientSurveyId',
    { patientSurveyId },
    { patientTvId },
    cancelToken
  );

const deleteSurvey = async ({
  genericSurveyId,
}: {
  genericSurveyId: string,
}): Promise<ErrorResponse | DeleteResponse<'/providers/surveys'>> =>
  apis.deleteT<'/providers/surveys'>(
    '/providers/surveys',
    {},
    { genericSurveyId }
  );

const sendDefaultEnrolledInstrumentToPatient = async (params: {
  genericSurveyId: GenericSurveyId,
  patientTvId: PatientTvId,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/surveys/:genericSurveyId/default/:patientTvId/send'>
> =>
  apis.postT<'/providers/surveys/:genericSurveyId/default/:patientTvId/send'>(
    '/providers/surveys/:genericSurveyId/default/:patientTvId/send',
    params,
    {}
  );

const sendDefaultEnrolledInstrumentToPatientNow = async (params: {
  genericSurveyId: GenericSurveyId,
  patientTvId: PatientTvId,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/surveys/:genericSurveyId/default/:patientTvId/send/now'>
> =>
  apis.postT<'/providers/surveys/:genericSurveyId/default/:patientTvId/send/now'>(
    '/providers/surveys/:genericSurveyId/default/:patientTvId/send/now',
    params,
    {}
  );

const setDefaultEnrolled = async ({
  genericSurveyId,
  defaultEnrollment,
}: {
  genericSurveyId: string,
  defaultEnrollment: boolean,
}): Promise<
  ErrorResponse | PostResponse<'/providers/surveys/:genericSurveyId/default'>
> =>
  apis.postT<'/providers/surveys/:genericSurveyId/default'>(
    '/providers/surveys/:genericSurveyId/default',
    { genericSurveyId },
    { defaultEnrollment }
  );

const fetchInstruments = async ({
  includeDeprecated,
  genericSurveyIds,
}: {|
  includeDeprecated: boolean,
  genericSurveyIds?: Array<GenericSurveyId>,
  cancelToken?: CancelToken,
|}): Promise<ErrorResponse | GetResponse<'/providers/surveys'>> =>
  apis.getT<'/providers/surveys'>(
    '/providers/surveys',
    {},
    { includeDeprecated, genericSurveyIds }
  );

const fetchSelectedPatientSurveys = async ({
  genericQuestionId,
  surveyJwtCode,
}: {|
  genericQuestionId: string,
  surveyJwtCode: string,
  cancelToken?: CancelToken,
|}): Promise<ErrorResponse | GetResponse<'/users/selectedPatientSurveys'>> =>
  apis.getT<'/users/selectedPatientSurveys'>(
    '/users/selectedPatientSurveys',
    {},
    { genericQuestionId, surveyJwtCode }
  );

export const scheduleItemFromGenericSurveyBase = async ({
  patientTvId,
  ...instrumentMetadata
}: {
  ...$Diff<ProviderSurveyForGenericT, { genericSurveyId: any }>,
  patientTvId: PatientTvId,
  name: string,
  questions: Array<AnyQuestionDataT>,
  baseGenericSurveyId: GenericSurveyId,
}): Promise<
  | ErrorResponse
  | PostResponse<'/provider/patients/:patientTvId/shortcut/genericSurvey'>
> =>
  apis.postT<'/provider/patients/:patientTvId/shortcut/genericSurvey'>(
    '/provider/patients/:patientTvId/shortcut/genericSurvey',
    { patientTvId },
    instrumentMetadata
  );

// creates brand new related instrument (generic survey and recurring survey)
export const createRelatedInstrumentForPatient = async ({
  parentRecurringSurveyId,
  name,
  patientTvId,
  questions,
}: {
  parentRecurringSurveyId: RecurringSurveyId,
  name: string,
  patientTvId: PatientTvId,
  questions: Array<AnyQuestionDataT>,
}): Promise<
  | ErrorResponse
  | PutResponse<'/providers/patients/:patientTvId/relatedInstruments'>
> =>
  apis.putT<'/providers/patients/:patientTvId/relatedInstruments'>(
    '/providers/patients/:patientTvId/relatedInstruments',
    { patientTvId },
    { name, parentRecurringSurveyId, questions }
  );

// fetches or creates related recurring survey based on target generic survey id
export const fetchOrCreateRelatedInstrumentRecurringSurvey = async ({
  parentRecurringSurveyId,
  targetGenericSurveyId,
  patientTvId,
}: {
  parentRecurringSurveyId: RecurringSurveyId,
  patientTvId: PatientTvId,
  targetGenericSurveyId: GenericSurveyId,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patients/:patientTvId/relatedInstruments'>
> =>
  apis.postT<'/providers/patients/:patientTvId/relatedInstruments'>(
    '/providers/patients/:patientTvId/relatedInstruments',
    { patientTvId },
    { parentRecurringSurveyId, targetGenericSurveyId }
  );

export const fetchRelatedInstrumentsForPatient = async ({
  patientTvId,
  recurringSurveyId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  recurringSurveyId: RecurringSurveyId,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patients/:patientTvId/relatedInstruments'>
> =>
  apis.getT<'/providers/patients/:patientTvId/relatedInstruments'>(
    '/providers/patients/:patientTvId/relatedInstruments',
    { patientTvId },
    { recurringSurveyId },
    cancelToken
  );

const fetchInstrumentOverview = async ({
  includeDeprecated,
  cancelToken,
  instrumentTypes,
}: {
  includeDeprecated: boolean,
  instrumentTypes?: Array<InstrumentTypes>,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/surveys/overview'>> =>
  apis.getT<'/providers/surveys/overview'>(
    '/providers/surveys/overview',
    {},
    { instrumentTypes, includeDeprecated },
    cancelToken
  );

export const fetchEnrolledInstruments = async ({
  patientTvId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/:patientTvId/items'>
> =>
  apis.getT<'/providers/patients/:patientTvId/items'>(
    '/providers/patients/:patientTvId/items',
    { patientTvId },
    {},
    cancelToken
  );

const fetchPatientById = async ({
  patientId,
  cancelToken,
}: {
  patientId: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/patients/:patientTvId'>> =>
  apis.getT<'/providers/patients/:patientTvId'>(
    '/providers/patients/:patientTvId',
    { patientTvId: patientId },
    {},
    cancelToken
  );

const sendPatientMessage = async ({
  message,
}: {|
  message: string,
|}): Promise<ErrorResponse | PostResponse<'/patient/messaging'>> =>
  apis.postT<'/patient/messaging'>('/patient/messaging', {}, { message });

const fetchAuditLogs = async ({
  providerTvId,
}: {
  providerTvId: NurseTvId | DoctorTvId,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/audits/:providerTvId'>> =>
  apis.getT<'/clinicAdmin/audits/:providerTvId'>(
    '/clinicAdmin/audits/:providerTvId',
    { providerTvId },
    {}
  );

const fetchDataSources = async ({
  cancelToken,
}: {
  cancelToken?: ?CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/dataSource'>> =>
  apis.getT<'/providers/dataSource'>(
    '/providers/dataSource',
    {},
    {},
    cancelToken
  );

const fetchDataSourceById = async ({
  dataSourceId,
}: {
  dataSourceId: string,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/dataSource/:dataSourceId'>
> =>
  apis.getT<'/clinicAdmin/dataSource/:dataSourceId'>(
    '/clinicAdmin/dataSource/:dataSourceId',
    { dataSourceId },
    {}
  );

const configureDataSource = async ({
  fields,
  titleField,
  dataSourceName,
  existingDataSourceId,
}: {
  fields: Array<string>,
  titleField: string,
  existingDataSourceId: string | null,
  dataSourceName: string,
}): Promise<ErrorResponse | PutResponse<'/clinicAdmin/dataSource'>> =>
  apis.putT<'/clinicAdmin/dataSource'>(
    '/clinicAdmin/dataSource',
    {},
    { fields, existingDataSourceId, titleField, dataSourceName }
  );

const updateDataItemsForDataSource = async ({
  dataSourceId,
  dataSource,
}: {
  dataSourceId: string,
  dataSource: Array<{
    id: string,
    fields: {
      [key: string]: string,
    },
  }>,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/dataSource'>> =>
  apis.postT<'/clinicAdmin/dataSource'>(
    '/clinicAdmin/dataSource',
    {},
    { dataSourceId, dataSource }
  );

const queryDataSource = async ({
  dataSourceId,
  cancelToken,
  searchTerm,
}: {
  dataSourceId: string,
  searchTerm: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/dataSource/:dataSourceId/query'>
> =>
  apis.getT<'/providers/dataSource/:dataSourceId/query'>(
    '/providers/dataSource/:dataSourceId/query',
    { dataSourceId },
    { searchTerm },
    cancelToken
  );

const fetchPatients = async ({
  statusFilters,
  groupFilters,
  cancelToken,
}: {|
  statusFilters: ?Array<StatusId>,
  cancelToken?: ?CancelToken,
  groupFilters: ?Array<GroupId>,
|}): Promise<ErrorResponse | GetResponse<'/providers/patients'>> =>
  apis.getT<'/providers/patients'>(
    '/providers/patients',
    {},
    {
      statusFilters,
      groupFilters,
    },
    cancelToken
  );

const getPushDevices = async ({
  cancelToken,
}: {
  cancelToken?: ?CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/push/settings'>> =>
  apis.getT<'/providers/push/settings'>(
    '/providers/push/settings',
    {},
    {},
    cancelToken
  );

const addPushDevice = async ({
  pushToken,
}: {
  pushToken: string,
}): Promise<ErrorResponse | PostResponse<'/providers/push/settings'>> =>
  apis.postT<'/providers/push/settings'>(
    '/providers/push/settings',
    {},
    { pushToken }
  );

const removePushDevice = async ({
  pushDeviceId,
}: {
  pushDeviceId: string,
}): Promise<ErrorResponse | DeleteResponse<'/providers/push/settings'>> =>
  apis.deleteT<'/providers/push/settings'>(
    '/providers/push/settings',
    {},
    { pushDeviceId }
  );

const fetchPatientList = async ({
  statusFilters,
  groupFilters,
  cancelToken,
}: {|
  statusFilters: ?Array<StatusId>,
  cancelToken?: ?CancelToken,
  groupFilters: ?Array<GroupId>,
|}): Promise<ErrorResponse | GetResponse<'/providers/patients/list'>> =>
  apis.getT<'/providers/patients/list'>(
    '/providers/patients/list',
    {},
    {
      statusFilters,
      groupFilters,
    },
    cancelToken
  );

const queryPatientsFromCache = async ({
  cancelToken,
  ...data
}: {
  searchTerm?: ?string,
  statusFilters?: ?Array<string>,
  groupFilters?: ?Array<string>,
  priorityOrdering: PriorityOrderingT,
  limit: number,
  offset: number,
  cancelToken?: ?CancelToken,
  expression?: ?string,
}): Promise<ErrorResponse | GetResponse<'/providers/patients/cache'>> =>
  apis.getT<'/providers/patients/cache'>(
    '/providers/patients/cache',
    {},
    {
      ...data,
      searchTerm: data.searchTerm || null,
      groupFilters: data.groupFilters || null,
      statusFilters: data.statusFilters || null,
      expression: data.expression || null,
    },
    cancelToken
  );

const updatePatientInCache = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<ErrorResponse | PostResponse<'/providers/patients/cache/:tvid'>> =>
  apis.postT<'/providers/patients/cache/:tvid'>(
    '/providers/patients/cache/:tvid',
    { tvid: patientTvId },
    {}
  );

const fetchPatientFromCache = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<ErrorResponse | GetResponse<'/providers/patients/cache/:tvid'>> =>
  apis.getT<'/providers/patients/cache/:tvid'>(
    '/providers/patients/cache/:tvid',
    { tvid: patientTvId },
    {}
  );

const fetchPatientDetails = async ({
  patientTvIds,
  cancelToken,
}: {|
  patientTvIds: Array<PatientTvId>,
  cancelToken?: ?CancelToken,
|}): Promise<ErrorResponse | GetResponse<'/providers/patients/list/details'>> =>
  apis.getT<'/providers/patients/list/details'>(
    '/providers/patients/list/details',
    {},
    {
      patientTvIds,
    },
    cancelToken
  );

const fetchPatientsForGroup = async ({
  groupId,
  cancelToken,
}: {
  groupId: string,
  cancelToken?: ?CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/groups/:groupId'>
> =>
  apis.getT<'/providers/patients/groups/:groupId'>(
    '/providers/patients/groups/:groupId',
    { groupId },
    {},
    cancelToken
  );

const getPatientSurvey = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: PatientSurveyId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patientSurvey/:patientSurveyId'>
> =>
  apis.getT<'/providers/patientSurvey/:patientSurveyId'>(
    '/providers/patientSurvey/:patientSurveyId',
    { patientSurveyId },
    {},
    cancelToken
  );

const getPatientSurveyForPatient = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: PatientSurveyId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/patients/patientSurvey/:patientSurveyId'>
> =>
  apis.getT<'/patients/patientSurvey/:patientSurveyId'>(
    '/patients/patientSurvey/:patientSurveyId',
    { patientSurveyId },
    {},
    cancelToken
  );

const getPatientSurveys = async ({
  responseQuery,
  cancelToken,
}: {
  responseQuery: GetRequest<'/providers/patientSurvey'>,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/patientSurvey'>> =>
  apis.getT<'/providers/patientSurvey'>(
    '/providers/patientSurvey',
    {},
    responseQuery,
    cancelToken
  );

const fetchSurveyFormatWithPatientSurvey = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | PostResponse<'/providers/surveys/:patientSurveyId'>
> =>
  apis.postT<'/providers/surveys/:patientSurveyId'>(
    '/providers/surveys/:patientSurveyId',
    { patientSurveyId },
    {},
    cancelToken
  );

const fetchProvidersInClinic = async ({
  patientTvId,
  providerTagIds,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  providerTagIds: Array<string>,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/:patientTvId/careteam'>> =>
  apis.getT<'/providers/:patientTvId/careteam'>(
    '/providers/:patientTvId/careteam',
    {
      patientTvId,
    },
    {
      providerTagIds,
    },
    cancelToken
  );

const createSurveyResponseForPatient: any = pDebounce(
  async ({
    patientId: patientTvId,
    cancelToken,
    isComplete,
    ...data
  }: {
    patientSurveyId: string,
    response: SurveyResponseT,
    patientId: string,
    responseId: ResponseId,
    isComplete: boolean,
    cancelToken: CancelToken,
    updatedQuestions: Array<QuestionId>,
  }): Promise<ErrorResponse | PostResponse<'/users/:patientTvId/responses'>> =>
    apis.postT<'/users/:patientTvId/responses'>(
      '/users/:patientTvId/responses',
      {
        patientTvId,
      },
      {
        ...data,
        responseCompletion: isComplete ? 'Full' : 'Partial',
      },
      cancelToken
    ),
  500
);

async function fetchPrefilledSurveys(): Promise<
  | ErrorResponse
  | {
      Status: 'OK',
      Response: Array<{ id: string, surveyTitle: string }>,
    }
> {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return {
    Status: 'OK',
    Response: await fetchPrefilledSurvey(),
  };
}

async function fetchPrefilledSurveyById({
  prefilledSurveyId,
}: {
  prefilledSurveyId: string,
}): Promise<
  | ErrorResponse
  | {
      Status: 'OK',
      Response: PrefilledSurveyT,
    }
> {
  const prefilledSurvey = fetchPrefilledSurveyWithId(prefilledSurveyId);
  return prefilledSurvey
    ? {
        Status: 'OK',
        Response: prefilledSurvey,
      }
    : {
        Status: 'Error',
        Response: 'Unable to find survey',
      };
}

const addPatientToInstrument = async (
  patientTvId: PatientTvId,
  survey: ProviderSurveyForGenericT
): Promise<
  ErrorResponse | PostResponse<'/providers/patients/:patientTvId/genericSurvey'>
> =>
  apis.postT<'/providers/patients/:patientTvId/genericSurvey'>(
    '/providers/patients/:patientTvId/genericSurvey',
    {
      patientTvId,
    },
    {
      survey,
    }
  );
const submitAdhoc = async ({
  recurringSurveyId,
  isComplete,
  ...surveyData
}: {
  recurringSurveyId: string,
  response: SurveyResponseT,
  responseId: ResponseId,
  isComplete: boolean,
  updatedQuestions: Array<QuestionId>,
}): Promise<
  ErrorResponse | PostResponse<'/patients/responses/adhoc/:recurringSurveyId'>
> =>
  apis.postT<'/patients/responses/adhoc/:recurringSurveyId'>(
    '/patients/responses/adhoc/:recurringSurveyId',
    { recurringSurveyId },
    {
      ...surveyData,
      responseCompletion: isComplete ? 'Full' : 'Partial',
    }
  );

const submitAdhocWithCode = async (
  recurringSurveyId: string,
  response: SurveyResponseT,
  surveyCode: string,
  updatedQuestions: Array<QuestionId>,
  responseId: ResponseId,
  isComplete: boolean
): Promise<
  ErrorResponse | PostResponse<'/patients/surveys/code/adhoc/:surveyCode'>
> =>
  apis.postT<'/patients/surveys/code/adhoc/:surveyCode'>(
    '/patients/surveys/code/adhoc/:surveyCode',
    { surveyCode },
    {
      response,
      recurringSurveyId,
      responseId,
      updatedQuestions,
      responseCompletion: isComplete ? 'Full' : 'Partial',
    }
  );

const resendErroredMessage = async (
  patientTvId: PatientTvId,
  patientSurveyId: string,
  retry: boolean
): Promise<
  | ErrorResponse
  | PostResponse<'/providers/:patientSurveyId/message/:patientTvId/error'>
> =>
  apis.postT<'/providers/:patientSurveyId/message/:patientTvId/error'>(
    '/providers/:patientSurveyId/message/:patientTvId/error',
    { patientSurveyId, patientTvId },
    { retry }
  );

const testResponse = async ({
  cancelToken,
  ...props
}: {
  cancelToken?: CancelToken,
  responses: SurveyResponseT,
  responseCompletion: 'Partial' | 'Full',
  questions: Array<AnyQuestionDataT>,
  triagePage: TriagePageT,
  gptExtract: $PropertyType<GenericSurveyUploadedT, 'gptExtract'>,
  allowCSVExport: boolean,
  variables: Array<InstrumentVariableDataT>,

  // chatbot not applicable for demo mode
  instrumentType: $Keys<
    $Diff<
      InstrumentTypesMappingT,
      {
        appointment: any,
        'phone-call': any,
        chatbot: any,
        'provider-status': any,
      }
    >
  >,
  description: string,
  name: string,
}): Promise<ErrorResponse | PostResponse<'/providers/test/response'>> =>
  apis.postT<'/providers/test/response'>(
    '/providers/test/response',
    {},
    props,
    cancelToken
  );

const testSendChatbotMessage = async ({
  chatbotId,
  message,
  cancelToken,
  currentTaskId,
}: {
  chatbotId: string,
  message: string,
  currentTaskId: ?string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | PostResponse<'/providers/chatbot/:chatbotId/test'>
> =>
  apis.postT<'/providers/chatbot/:chatbotId/test'>(
    '/providers/chatbot/:chatbotId/test',
    { chatbotId },
    { message, currentTaskId },
    cancelToken
  );

const trainChatbot = async ({
  chatbotId,
  tasks,
}: {
  chatbotId: string,
  tasks: Array<ChatbotTaskT>,
}): Promise<ErrorResponse | PostResponse<'/providers/chatbot/:chatbotId'>> =>
  apis.postT<'/providers/chatbot/:chatbotId'>(
    '/providers/chatbot/:chatbotId',
    { chatbotId },
    { tasks }
  );

const fetchChatbotConfig = async ({
  chatbotId,
  cancelToken,
}: {
  chatbotId: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/chatbot/:chatbotId'>> =>
  apis.getT<'/providers/chatbot/:chatbotId'>(
    '/providers/chatbot/:chatbotId',
    { chatbotId },
    {},
    cancelToken
  );

const extendSession = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | PostResponse<'/users/extend'>> =>
  apis.postT<'/users/extend'>('/users/extend', {}, {}, cancelToken);

const sendAdhocPatientSurvey = async (
  patientTvId: string,
  recurringSurveyId: string,
  startDatePreference: SurveyStartDates
): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId'>
> =>
  apis.postT<'/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId'>(
    '/providers/patients/:patientTvId/recurringSurvey/:recurringSurveyId',
    {
      patientTvId,
      recurringSurveyId,
    },
    { startDatePreference }
  );

export const getStatusCountsForGenericSurvey = async ({
  genericSurveyId,
  cancelToken,
}: {
  genericSurveyId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/clinicAdmin/instruments/:genericSurveyId/status/counts'>
> =>
  apis.getT<'/clinicAdmin/instruments/:genericSurveyId/status/counts'>(
    '/clinicAdmin/instruments/:genericSurveyId/status/counts',
    { genericSurveyId },
    {},
    cancelToken
  );

export const getTargetRevisionForGenericSurvey = async ({
  targetRevisionId,
  cancelToken,
}: {
  targetRevisionId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/instruments/commits/revisions'>
> =>
  apis.getT<'/clinicAdmin/instruments/commits/revisions'>(
    '/clinicAdmin/instruments/commits/revisions',
    {},
    { targetRevisionId },
    cancelToken
  );

export const getRevisionsForGenericSurvey = async ({
  genericSurveyId,
  cancelToken,
}: {
  genericSurveyId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/clinicAdmin/instruments/:genericSurveyId/commits'>
> =>
  apis.getT<'/clinicAdmin/instruments/:genericSurveyId/commits'>(
    '/clinicAdmin/instruments/:genericSurveyId/commits',
    { genericSurveyId },
    {},
    cancelToken
  );

const updateInstrumentData = async ({
  genericSurveyMetadata,
  lastUpdateId,
  genericSurveyId,
  commitMessage,
}: {
  genericSurveyMetadata: $Diff<
    GenericSurveyUploadedT,
    {
      type: any,
      providerTagIds: any,
      providerStatus: any,
      interactionType: any,
    }
  >,
  commitMessage: string,
  lastUpdateId: string,
  genericSurveyId: GenericSurveyId,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/instruments/update'>> =>
  apis.postT<'/clinicAdmin/instruments/update'>(
    '/clinicAdmin/instruments/update',
    {},
    { ...genericSurveyMetadata, lastUpdateId, commitMessage, genericSurveyId }
  );

const setSurveyTags = async ({
  patientSurveyId,
  patientTvId,
  updatedTags,
}: {
  patientSurveyId: PatientSurveyId,
  patientTvId: PatientTvId,
  updatedTags: Array<string>,
}): Promise<
  ErrorResponse | PostResponse<'/providers/patientSurvey/:patientSurveyId/tags'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/tags'>(
    '/providers/patientSurvey/:patientSurveyId/tags',
    {
      patientSurveyId,
    },
    {
      updatedTags,
      patientTvId,
    }
  );

const sendSurveyMessage = async ({
  patientId,
  patientSurveyId,
  messageType,
}: {
  patientSurveyId: PatientSurveyId,
  patientId: PatientTvId,
  messageType: 'reminder' | 'creation',
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/reminder'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/reminder'>(
    '/providers/patientSurvey/:patientSurveyId/reminder',
    {
      patientSurveyId,
    },
    {
      patientTvId: patientId,
      reminderType: messageType,
    }
  );

export const resendConsentMessageForPatient = async ({
  patientTvId,
  messageId,
}: {
  patientTvId: PatientTvId,
  messageId: string,
}): Promise<ErrorResponse | PostResponse<'/providers/consent/retry'>> =>
  apis.postT<'/providers/consent/retry'>(
    '/providers/consent/retry',
    {},
    { patientTvId, messageId }
  );

const getConsentMessage = async (): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinicConsent'>
> =>
  apis.getT<'/clinicAdmin/clinicConsent'>('/clinicAdmin/clinicConsent', {}, {});

const postConsentMessage = async (
  consent: ConsentT
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinicConsent'>> =>
  apis.postT<'/clinicAdmin/clinicConsent'>(
    '/clinicAdmin/clinicConsent',
    {},
    consent
  );

const toggleGroupNotifications = async ({
  providerTvId,
  notificationsEnabled,
  groupId,
}: {
  providerTvId: DoctorTvId | NurseTvId,
  notificationsEnabled: boolean,
  groupId: string,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/groups/notifications/toggle'>
> =>
  apis.postT<'/clinicAdmin/groups/notifications/toggle'>(
    '/clinicAdmin/groups/notifications/toggle',
    {},
    {
      providerTvId,
      groupId,
      notificationsEnabled,
    }
  );

const getProviderCareTeam = async (): Promise<
  ErrorResponse | GetResponse<'/providers/careteam'>
> => apis.getT<'/providers/careteam'>('/providers/careteam', {}, {});

const getClinicUsers = async (): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/users'>
> => apis.getT<'/clinicAdmin/users'>('/clinicAdmin/users', {}, {});

const getClinicAdminCareTeam = async (): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/careTeam'>
> => apis.getT<'/clinicAdmin/careTeam'>('/clinicAdmin/careTeam', {}, {});

const fetchSurveyCodeForShortLink = async (
  shortLink: string,
  cancelToken?: CancelToken
): Promise<ErrorResponse | GetResponse<'/surveycodes/shortlink/code'>> =>
  apis.getT<'/surveycodes/shortlink/code'>(
    '/surveycodes/shortlink/code',
    {},
    { shortLink },
    cancelToken
  );

const fetchCalendarLink = async ({
  calendarURL,
  cancelToken,
}: {
  calendarURL: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/users/calendar/parse'>> =>
  apis.getT<'/users/calendar/parse'>(
    '/users/calendar/parse',
    {},
    { calendarURL },
    cancelToken
  );

const deletePatientSurvey = async (
  patientTvId: PatientTvId,
  patientSurveyId: string
): Promise<
  | ErrorResponse
  | DeleteResponse<'/providers/patients/:patientTvId/patientSurvey/:patientSurveyId'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId/patientSurvey/:patientSurveyId'>(
    '/providers/patients/:patientTvId/patientSurvey/:patientSurveyId',
    {
      patientTvId,
      patientSurveyId,
    },
    {}
  );

const getPatientCareTeam = async ({
  patientTvId,
  includeCurrentUser,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  includeCurrentUser: boolean,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/:patientTvId/careteam'>
> =>
  apis.getT<'/providers/patients/:patientTvId/careteam'>(
    '/providers/patients/:patientTvId/careteam',
    { patientTvId },
    { includeCurrentUser },
    cancelToken
  );

export const markComplete = async ({
  patientSurveyId,
  patientTvId,
  sendCompletionMessage,
}: {
  patientSurveyId: string,
  patientTvId: string,
  sendCompletionMessage: boolean,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/complete'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/complete'>(
    '/providers/patientSurvey/:patientSurveyId/complete',
    { patientSurveyId },
    { patientTvId, sendCompletionMessage }
  );

const updateNurseHours = async (
  days: ProviderAvailabilityDays,
  timezone: string
): Promise<ErrorResponse | PostResponse<'/providers/nurses/availability'>> =>
  apis.postT<'/providers/nurses/availability'>(
    '/providers/nurses/availability',
    {},
    {
      days,
      timezone,
    }
  );

const getNurseHours = async (): Promise<
  ErrorResponse | GetResponse<'/providers/nurses/availability'>
> =>
  apis.getT<'/providers/nurses/availability'>(
    '/providers/nurses/availability',
    {},
    {}
  );

const getNurseNotificationType = async (): Promise<
  ErrorResponse | GetResponse<'/providers/nurses/notificationType'>
> =>
  apis.getT<'/providers/nurses/notificationType'>(
    '/providers/nurses/notificationType',
    {},
    {}
  );

const updateNurseNotificationType = async ({
  notificationChannels,
  notificationType,
}: {
  notificationChannels: Array<'email' | 'text'>,
  notificationType: Array<ProviderNotifTypes>,
}): Promise<
  ErrorResponse | PostResponse<'/providers/nurses/notificationType'>
> =>
  apis.postT<'/providers/nurses/notificationType'>(
    '/providers/nurses/notificationType',
    {},
    {
      notificationChannels,
      notificationType,
    }
  );

const getNursePhoneNumber = async (): Promise<
  ErrorResponse | GetResponse<'/providers/nurses/phoneNumber'>
> =>
  apis.getT<'/providers/nurses/phoneNumber'>(
    '/providers/nurses/phoneNumber',
    {},
    {}
  );

const setNursePhoneNumber = async (
  phoneNumber: null | string
): Promise<ErrorResponse | PostResponse<'/providers/nurses/phoneNumber'>> =>
  apis.postT<'/providers/nurses/phoneNumber'>(
    '/providers/nurses/phoneNumber',
    {},
    { phoneNumber }
  );

const createReferral = async ({
  targetProviderTvId,
  patientSurveyId,
  superscoreDialogId,
}: {
  targetProviderTvId: DoctorTvId | NurseTvId,
  superscoreDialogId: string,
  patientSurveyId: string,
}): Promise<ErrorResponse | PostResponse<'/provider/refer/:patientSurveyId'>> =>
  apis.postT<'/provider/refer/:patientSurveyId'>(
    '/provider/refer/:patientSurveyId',
    { patientSurveyId },
    { targetProviderTvId, superscoreDialogId }
  );

const fetchAllReferrals = async ({
  patientTvId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/provider/referrals'>> =>
  apis.getT<'/provider/referrals'>(
    '/provider/referrals',
    {},
    { patientTvId },
    cancelToken
  );

const markAllMessagesAsRead = async ({
  providerTvId,
}: {
  providerTvId: DoctorTvId | NurseTvId,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/messages/read/:providerTvId'>
> =>
  apis.postT<'/clinicAdmin/messages/read/:providerTvId'>(
    '/clinicAdmin/messages/read/:providerTvId',
    { providerTvId },
    {}
  );

const fetchReferral = async ({
  referralId,
  patientTvId,
  cancelToken,
}: {
  referralId: string,
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/provider/referrals/:referralId'>> =>
  apis.getT<'/provider/referrals/:referralId'>(
    '/provider/referrals/:referralId',
    { referralId },
    { patientTvId },
    cancelToken
  );

export const attachNoteToPtSurvey = async ({
  notes,
  patientSurveyId,
}: {
  notes: string,
  patientSurveyId: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/patientSurvey/:patientSurveyId/note'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/note'>(
    '/providers/patientSurvey/:patientSurveyId/note',
    { patientSurveyId },
    { notes }
  );

export const createProviderNotification = async ({
  patientTvId,
  targetProviderTvIds,
  notes,
}: {
  patientTvId: PatientTvId,
  targetProviderTvIds: Array<DoctorTvId | NurseTvId>,
  notes: string,
}): Promise<ErrorResponse | PostResponse<'/provider/:patientTvId/notify'>> =>
  apis.postT<'/provider/:patientTvId/notify'>(
    '/provider/:patientTvId/notify',
    { patientTvId },
    { targetProviderTvIds, notes }
  );

export const completeReferral = async ({
  patientTvId,
  status,
  referralId,
}: {
  status: 'cancelled' | 'triaged',
  patientTvId: PatientTvId,
  referralId: string,
}): Promise<ErrorResponse | PostResponse<'/provider/referrals/:referralId'>> =>
  apis.postT<'/provider/referrals/:referralId'>(
    '/provider/referrals/:referralId',
    { referralId },
    { status, patientTvId }
  );

const confirmNursePhoneNumber = async (
  encryptedPhoneNumber: string,
  code: string
): Promise<
  ErrorResponse | PostResponse<'/providers/nurses/phoneNumber/confirm'>
> =>
  apis.postT<'/providers/nurses/phoneNumber/confirm'>(
    '/providers/nurses/phoneNumber/confirm',
    {},
    { encryptedPhoneNumber, code }
  );

const setDefaultGroup = async ({
  groupId,
  providerTvId,
}: {
  groupId: string,
  providerTvId: DoctorTvId | NurseTvId,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/groups/:providerTvId/default'>
> =>
  apis.postT<'/clinicAdmin/groups/:providerTvId/default'>(
    '/clinicAdmin/groups/:providerTvId/default',
    { providerTvId },
    { groupId }
  );

const addPatientToGroupAsProvider = async (
  patientTvId: string,
  groupId: string
): Promise<ErrorResponse | PutResponse<'/providers/groups/manage'>> =>
  apis.putT<'/providers/groups/manage'>(
    '/providers/groups/manage',
    {},
    { groupId, patientTvId }
  );

const deletePatientToGroupAsProvider = async (
  patientTvId: string,
  groupId: string
): Promise<ErrorResponse | DeleteResponse<'/providers/groups/manage'>> =>
  apis.deleteT<'/providers/groups/manage'>(
    '/providers/groups/manage',
    {},
    { groupId, patientTvId }
  );

const deleteUserFromGroup = async (
  userId: string, // provider or patient tvid
  groupId: string
): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/groups/manage'>> =>
  apis.deleteT<'/clinicAdmin/groups/manage'>(
    '/clinicAdmin/groups/manage',
    {},
    { groupId, userId }
  );

const addUserToGroup = async (
  userId: string, // provider or patient tvid
  groupId: string
): Promise<ErrorResponse | PutResponse<'/clinicAdmin/groups/manage'>> =>
  apis.putT<'/clinicAdmin/groups/manage'>(
    '/clinicAdmin/groups/manage',
    {},
    { groupId, userId }
  );

const getProviderTags = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/tags/all'>> =>
  apis.getT<'/providers/tags/all'>('/providers/tags/all', {}, {}, cancelToken);

const assignProviderTagForProvider = async ({
  providerTvId,
  tagId,
}: {
  providerTvId: DoctorTvId | NurseTvId,
  tagId: string,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/provider/:providerTvId/tags'>
> =>
  apis.postT<'/clinicAdmin/provider/:providerTvId/tags'>(
    '/clinicAdmin/provider/:providerTvId/tags',
    { providerTvId },
    { tagId }
  );

const deleteProviderTagForProvider = async ({
  providerTvId,
  tagId,
}: {
  providerTvId: DoctorTvId | NurseTvId,
  tagId: string,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/provider/:providerTvId/tags'>
> =>
  apis.deleteT<'/clinicAdmin/provider/:providerTvId/tags'>(
    '/clinicAdmin/provider/:providerTvId/tags',
    { providerTvId },
    { tagId }
  );

const createProviderTag = async ({
  name,
}: {
  name: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/provider/tags'>> =>
  apis.postT<'/clinicAdmin/provider/tags'>(
    '/clinicAdmin/provider/tags',
    {},
    { name }
  );

const deleteProviderTag = async ({
  tagId,
}: {
  tagId: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/provider/tags'>> =>
  apis.deleteT<'/clinicAdmin/provider/tags'>(
    '/clinicAdmin/provider/tags',
    {},
    { tagId }
  );

const fetchSurveyFormat = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: string,
  cancelToken: CancelToken,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/surveys/:patientSurveyId/surveyFormat'>
> =>
  apis.postT<'/providers/surveys/:patientSurveyId/surveyFormat'>(
    '/providers/surveys/:patientSurveyId/surveyFormat',
    {
      patientSurveyId,
    },
    {},
    cancelToken
  );

const fetchPatientSurveyForPatient = async ({
  patientSurveyId,
  cancelToken,
}: {
  patientSurveyId: string,
  cancelToken: CancelToken,
}): Promise<
  | ErrorResponse
  | PostResponse<'/patients/patientSurvey/:patientSurveyId/detail'>
> =>
  apis.postT<'/patients/patientSurvey/:patientSurveyId/detail'>(
    '/patients/patientSurvey/:patientSurveyId/detail',
    {
      patientSurveyId,
    },
    {},
    cancelToken
  );

export const triageIncompletePatientSurvey = async ({
  patientSurveyId,
  notes,
}: {
  patientSurveyId: string,
  notes: string,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/status/triage'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/status/triage'>(
    '/providers/patientSurvey/:patientSurveyId/status/triage',
    { patientSurveyId },
    {
      notes,
      type: 'incomplete-patient-survey',
    }
  );

export const triageStatusOnlyPatientSurvey = async ({
  patientSurveyId,
  notes,
}: {
  patientSurveyId: string,
  notes: ?string,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/status/triage'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/status/triage'>(
    '/providers/patientSurvey/:patientSurveyId/status/triage',
    { patientSurveyId },
    {
      notes,
      type: 'triage-note',
    }
  );

export const updateSurveyResponse = async ({
  patientSurveyId,
  ...data
}: {
  patientSurveyId: PatientSurveyId,
  newResponse: SurveyResponseT,
  updatedQuestions: Array<QuestionId>,
  responseCompletion: 'Partial' | 'Full',
}): Promise<
  ErrorResponse | PostResponse<'/providers/:patientSurveyId/responses/update'>
> =>
  apis.postT<'/providers/:patientSurveyId/responses/update'>(
    '/providers/:patientSurveyId/responses/update',
    { patientSurveyId },
    data
  );

const setUserGroups = async (
  userId: string, // provider or patient tvid
  groupIds: Array<string>
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/groups/manage'>> =>
  apis.postT<'/clinicAdmin/groups/manage'>(
    '/clinicAdmin/groups/manage',
    {},
    { groupIds, userId }
  );

const createGroup = async (
  groupName: string
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/groups'>> =>
  apis.postT<'/clinicAdmin/groups'>('/clinicAdmin/groups', {}, { groupName });

export const deleteGroup = async (data: {
  groupId: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/groups/:groupId'>> =>
  apis.deleteT<'/clinicAdmin/groups/:groupId'>(
    '/clinicAdmin/groups/:groupId',
    data,
    {}
  );

const createNewResponseForAdhoc = async (
  recurringSurveyId: string
): Promise<
  | ErrorResponse
  | PostResponse<'/patients/responses/adhoc/:recurringSurveyId/new'>
> =>
  apis.postT<'/patients/responses/adhoc/:recurringSurveyId/new'>(
    '/patients/responses/adhoc/:recurringSurveyId/new',
    { recurringSurveyId },
    {}
  );

const createNewResponseForAdhocWithSurveyCode = async (
  recurringSurveyId: string,
  surveyCode: string
): Promise<
  | ErrorResponse
  | PostResponse<'/patients/responses/adhoc/:recurringSurveyId/new/:surveyCode'>
> =>
  apis.postT<'/patients/responses/adhoc/:recurringSurveyId/new/:surveyCode'>(
    '/patients/responses/adhoc/:recurringSurveyId/new/:surveyCode',
    { recurringSurveyId, surveyCode },
    {}
  );

const getProviderAppointments = async (params: {
  startDate: DateInMillis,
  endDate: DateInMillis,
}): Promise<ErrorResponse | GetResponse<'/providers/appointments'>> =>
  apis.getT<'/providers/appointments'>('/providers/appointments', {}, params);

const deleteAppointment = async (
  patientAppointmentId: string
): Promise<ErrorResponse | DeleteResponse<'/providers/appointments'>> =>
  apis.deleteT<'/providers/appointments'>(
    '/providers/appointments',
    {},
    {
      patientAppointmentId,
    }
  );

const getAppointment = async ({
  patientTvId,
  patientAppointmentId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  patientAppointmentId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patients/:patientTvId/appointment/:patientAppointmentId'>
> =>
  apis.getT<'/providers/patients/:patientTvId/appointment/:patientAppointmentId'>(
    '/providers/patients/:patientTvId/appointment/:patientAppointmentId',
    {
      patientAppointmentId,
      patientTvId,
    },
    {},
    cancelToken
  );

const createAppointments = async (
  apptData: AppointmentCreationPayloadT
): Promise<ErrorResponse | PostResponse<'/providers/appointments'>> =>
  apis.postT<'/providers/appointments'>(
    '/providers/appointments',
    {},
    apptData
  );

const getAppointmentTags = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/appointmentTags'>> =>
  apis.getT<'/providers/appointmentTags'>(
    '/providers/appointmentTags',
    {},
    {},
    cancelToken
  );

const createAppointmentTag = async ({
  appointmentTag,
}: {
  appointmentTag: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/appointmentTags'>> =>
  apis.postT<'/clinicAdmin/appointmentTags'>(
    '/clinicAdmin/appointmentTags',
    {},
    {
      appointmentTag,
    }
  );

const getPatientActionMetadata = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/:patientTvId/actionMetadata'>
> =>
  apis.getT<'/providers/patients/:patientTvId/actionMetadata'>(
    '/providers/patients/:patientTvId/actionMetadata',
    { patientTvId },
    {}
  );

const deleteAppointmentTag = async ({
  appointmentTag,
}: {
  appointmentTag: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/appointmentTags'>> =>
  apis.deleteT<'/clinicAdmin/appointmentTags'>(
    '/clinicAdmin/appointmentTags',
    {},
    {
      appointmentTag,
    }
  );

const getMetadata = async (): Promise<
  ErrorResponse | GetResponse<'/users/metadata'>
> => apis.getT<'/users/metadata'>('/users/metadata', {}, {});

export const resolveStatusForSurvey = async ({
  patientSurveyId,
  statusId,
  patientTvId,
}: {
  patientSurveyId: string,
  statusId: string,
  patientTvId: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/responses/:patientSurveyId/resolve'>
> =>
  apis.postT<'/providers/responses/:patientSurveyId/resolve'>(
    '/providers/responses/:patientSurveyId/resolve',
    { patientSurveyId },
    { patientTvId, statusId }
  );

export const getSurveyMetadata = async ({
  patientSurveyId,
  patientTvId,
}: {
  patientSurveyId: string,
  patientTvId: string,
}): Promise<
  ErrorResponse | GetResponse<'/providers/responses/:patientSurveyId/metadata'>
> =>
  apis.getT<'/providers/responses/:patientSurveyId/metadata'>(
    '/providers/responses/:patientSurveyId/metadata',
    { patientSurveyId },
    { patientTvId }
  );

const fetchThreads = async ({
  patientTvId,
}: {
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | GetResponse<'/provider/messaging/:patientTvId/thread'>
> =>
  apis.getT<'/provider/messaging/:patientTvId/thread'>(
    '/provider/messaging/:patientTvId/thread',
    { patientTvId },
    {}
  );

export const fetchMessagesAllThreads = async ({
  patientId,
  before,
  after,
}: {
  patientId: PatientTvId,
  before: ?number,
  after: ?number,
}): Promise<
  ErrorResponse | GetResponse<'/provider/messaging/pdf/:patientTvId'>
> =>
  apis.getT<'/provider/messaging/pdf/:patientTvId'>(
    '/provider/messaging/pdf/:patientTvId',
    {
      patientTvId: patientId,
    },
    { before, after }
  );

const fetchChatbotMessages = async ({
  patientTvId,
  chatbotMetadata,
}: {|
  patientTvId: PatientTvId,
  chatbotMetadata: {|
    chatbotId: string,
  |},
|}): Promise<
  ErrorResponse | GetResponse<'/provider/:patientTvId/chatbot/messages'>
> =>
  apis.getT<'/provider/:patientTvId/chatbot/messages'>(
    '/provider/:patientTvId/chatbot/messages',
    { patientTvId },
    chatbotMetadata
  );

const createThreads = async ({
  patientTvId,
  threadUserIds,
  threadName,
}: {
  patientTvId: PatientTvId,
  threadUserIds: Array<DoctorTvId | NurseTvId | PatientTvId>,
  threadName: string,
}): Promise<
  ErrorResponse | PostResponse<'/provider/messaging/:patientTvId/thread'>
> =>
  apis.postT<'/provider/messaging/:patientTvId/thread'>(
    '/provider/messaging/:patientTvId/thread',
    { patientTvId },
    { threadUserIds, threadName }
  );

const addPatientStatus = async ({
  statusId,
  patientTvId,
}: {
  statusId: string,
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | PutResponse<'/providers/patients/:patientTvId/status'>
> =>
  apis.putT<'/providers/patients/:patientTvId/status'>(
    '/providers/patients/:patientTvId/status',
    { patientTvId },
    { statusId }
  );

const deletePatientStatus = async ({
  statusId,
  patientTvId,
}: {
  statusId: string,
  patientTvId: PatientTvId,
}): Promise<
  ErrorResponse | DeleteResponse<'/providers/patients/:patientTvId/status'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId/status'>(
    '/providers/patients/:patientTvId/status',
    { patientTvId },
    { statusId }
  );

const createClinicStatus = async ({
  statusName,
  statusColor,
  providerTagIds,
  isUrgent,
  isAlert,
}: {
  statusName: string,
  statusColor: string,
  providerTagIds: Array<string>,
  isAlert: boolean,
  isUrgent: boolean,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinics/status'>> =>
  apis.postT<'/clinicAdmin/clinics/status'>(
    '/clinicAdmin/clinics/status',
    {},
    {
      statusName,
      statusColor,
      providerTagIds,
      isAlert,
      isUrgent,
    }
  );

// get all clinic statuses that this provider has access to
// in aggregate
const getFilterableClinicStatus = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/clinics/status'>> =>
  apis.getT<'/providers/clinics/status'>(
    '/providers/clinics/status',
    {},
    {},
    cancelToken
  );

const getGlobalClinicStatus = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/clinics/status/global'>> =>
  apis.getT<'/providers/clinics/status/global'>(
    '/providers/clinics/status/global',
    {},
    {},
    cancelToken
  );

const createYuUser = async (): Promise<{
  Response: string,
  Status: string,
}> => ({
  Response: 'Invalid endpoint',
  Status: 'Error',
});

const deleteClinicStatus = async ({
  statusId,
}: {
  statusId: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/clinics/status'>> =>
  apis.deleteT<'/clinicAdmin/clinics/status'>(
    '/clinicAdmin/clinics/status',
    {},
    { statusId }
  );

const getPatientSurveyReminders = async ({
  patientSurveyId,
}: {
  patientSurveyId: string,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>
> =>
  apis.getT<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>(
    '/providers/patientSurvey/:patientSurveyId/reminder/manage',
    { patientSurveyId },
    {}
  );

const updateCreatePatientSurveyReminders = async ({
  patientSurveyId,
  reminderId,
  reminderDate,
}: {
  patientSurveyId: string,
  reminderId: ?string,
  reminderDate: DateInMillis,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>
> =>
  apis.postT<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>(
    '/providers/patientSurvey/:patientSurveyId/reminder/manage',
    { patientSurveyId },
    { reminderId, reminderDate }
  );

const deletePatientSurveyReminders = async ({
  patientSurveyId,
  reminderId,
}: {
  patientSurveyId: string,
  reminderId: string,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>
> =>
  apis.deleteT<'/providers/patientSurvey/:patientSurveyId/reminder/manage'>(
    '/providers/patientSurvey/:patientSurveyId/reminder/manage',
    { patientSurveyId },
    { reminderId }
  );

const startVideo = async ({
  patientSurveyId,
}: {
  patientSurveyId: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/:patientSurveyId/video/start'>
> =>
  apis.postT<'/providers/:patientSurveyId/video/start'>(
    '/providers/:patientSurveyId/video/start',
    { patientSurveyId },
    {}
  );

const joinVideoBySurveyCode = async ({
  surveyJwtCode,
}: {
  surveyJwtCode: string,
}): Promise<ErrorResponse | PostResponse<'/users/video/join/:surveyJwtCode'>> =>
  apis.postT<'/users/video/join/:surveyJwtCode'>(
    '/users/video/join/:surveyJwtCode',
    { surveyJwtCode },
    {}
  );

const joinVideo = async ({
  patientSurveyId,
}: {
  patientSurveyId: string,
}): Promise<
  ErrorResponse | PostResponse<'/users/:patientSurveyId/video/join'>
> =>
  apis.postT<'/users/:patientSurveyId/video/join'>(
    '/users/:patientSurveyId/video/join',
    { patientSurveyId },
    {}
  );

const endVideoSession = async ({
  patientSurveyId,
}: {
  patientSurveyId: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/:patientSurveyId/video/end'>
> =>
  apis.postT<'/providers/:patientSurveyId/video/end'>(
    '/providers/:patientSurveyId/video/end',
    { patientSurveyId },
    {}
  );

const setChatbotStatus = async ({
  patientSurveyId,
  status,
}: {
  patientSurveyId: string,
  status: 'Enabled' | 'Paused' | 'Stopped',
}): Promise<
  ErrorResponse | PostResponse<'/providers/chatbot/:patientSurveyId/status'>
> =>
  apis.postT<'/providers/chatbot/:patientSurveyId/status'>(
    '/providers/chatbot/:patientSurveyId/status',
    { patientSurveyId },
    { status }
  );

const createExerciseTag = async ({
  tagName,
}: {
  tagName: string,
}): Promise<ErrorResponse | PostResponse<'/providers/exercises/tags'>> =>
  apis.postT<'/providers/exercises/tags'>(
    '/providers/exercises/tags',
    {},
    { tagName }
  );

const fetchAllExerciseTags = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/exercises/tags'>> =>
  apis.getT<'/providers/exercises/tags'>(
    '/providers/exercises/tags',
    {},
    {},
    cancelToken
  );

const createExercise = async ({
  exerciseData,
}: {
  exerciseData: ExerciseItemT,
}): Promise<ErrorResponse | PostResponse<'/providers/exercises'>> =>
  apis.postT<'/providers/exercises'>(
    '/providers/exercises',
    {},
    { exerciseData }
  );

const getAllExercises = async ({
  cancelToken,
  exerciseIds,
  patientTvId,
}: {
  cancelToken?: CancelToken,
  exerciseIds?: Array<string>,
  patientTvId?: PatientTvId,
}): Promise<ErrorResponse | GetResponse<'/providers/exercises'>> =>
  apis.getT<'/providers/exercises'>(
    '/providers/exercises',
    {},
    { exerciseIds, patientTvId },
    cancelToken
  );

const searchExercises = async ({
  cancelToken,
  query,
  limit,
  patientTvId,
  includeDeprecated,
}: {
  cancelToken?: CancelToken,
  query: ?string,
  limit: number,
  includeDeprecated?: boolean,
  patientTvId?: PatientTvId,
}): Promise<ErrorResponse | GetResponse<'/providers/exercises/search'>> =>
  apis.getT<'/providers/exercises/search'>(
    '/providers/exercises/search',
    {},
    {
      query,
      limit,
      patientTvId,
      includeDeprecated,
    },
    cancelToken
  );

const deleteExercise = async ({
  exerciseId,
}: {
  exerciseId: string,
}): Promise<
  ErrorResponse | DeleteResponse<'/providers/exercises/:exerciseId'>
> =>
  apis.deleteT<'/providers/exercises/:exerciseId'>(
    '/providers/exercises/:exerciseId',
    { exerciseId },
    {}
  );

const getExerciseById = async ({
  exerciseId,
  cancelToken,
  patientTvId,
}: {
  exerciseId: string,
  cancelToken?: CancelToken,
  patientTvId?: PatientTvId,
}): Promise<ErrorResponse | GetResponse<'/providers/exercises/:exerciseId'>> =>
  apis.getT<'/providers/exercises/:exerciseId'>(
    '/providers/exercises/:exerciseId',
    { exerciseId },
    { patientTvId },
    cancelToken
  );

const getExercisesForSurvey = async ({
  exerciseIds,
  surveyJwtCode,
  cancelToken,
}: {
  exerciseIds: Array<string>,
  surveyJwtCode: ?string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/users/exercises'>> =>
  apis.getT<'/users/exercises'>(
    '/users/exercises',
    {},
    { exerciseIds, surveyJwtCode },
    cancelToken
  );

const deprecateCampaign = async ({
  campaignId,
}: {
  campaignId: string,
}): Promise<ErrorResponse | DeleteResponse<'/providers/campaigns'>> =>
  apis.deleteT<'/providers/campaigns'>(
    '/providers/campaigns',
    {},
    { campaignId }
  );

const getCampaigns = async ({
  cancelToken,
  includeDeprecated,
  includeOnlyVisibleOnCreation,
}: {
  cancelToken?: CancelToken,
  includeDeprecated: boolean,
  includeOnlyVisibleOnCreation?: ?boolean,
}): Promise<ErrorResponse | GetResponse<'/providers/campaigns'>> =>
  apis.getT<'/providers/campaigns'>(
    '/providers/campaigns',
    {},
    { includeOnlyVisibleOnCreation, includeDeprecated },
    cancelToken
  );

export const getCampaignItemForCampaign = async ({
  campaignId,
  campaignItemId,
  cancelToken,
}: {
  campaignId: string,
  campaignItemId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/campaigns/:campaignId/campaignItems/:campaignItemId'>
> =>
  apis.getT<'/providers/campaigns/:campaignId/campaignItems/:campaignItemId'>(
    '/providers/campaigns/:campaignId/campaignItems/:campaignItemId',
    { campaignId, campaignItemId },
    {},
    cancelToken
  );

const getCampaignItemsForCampaign = async ({
  campaignId,
  cancelToken,
}: {
  campaignId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/campaigns/:campaignId/campaignItems'>
> =>
  apis.getT<'/providers/campaigns/:campaignId/campaignItems'>(
    '/providers/campaigns/:campaignId/campaignItems',
    { campaignId },
    {},
    cancelToken
  );

const getPatientsEnrolledInCampaign = async ({
  campaignId,
  limit,
  offset,
  cancelToken,
}: {
  campaignId: string,
  limit: number,
  offset: number,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/campaigns/:campaignId/patients'>
> =>
  apis.getT<'/providers/campaigns/:campaignId/patients'>(
    '/providers/campaigns/:campaignId/patients',
    { campaignId },
    {
      limit,
      offset,
    },
    cancelToken
  );

const getEnrolledCampaignsForPatient = async ({
  patientTvId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/:patientTvId/campaigns'>
> =>
  apis.getT<'/providers/patients/:patientTvId/campaigns'>(
    '/providers/patients/:patientTvId/campaigns',
    { patientTvId },
    {},
    cancelToken
  );

const enrollPatientInCampaign = async ({
  patientTvId,
  campaignId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  campaignId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | PostResponse<'/providers/patients/:patientTvId/campaigns'>
> =>
  apis.postT<'/providers/patients/:patientTvId/campaigns'>(
    '/providers/patients/:patientTvId/campaigns',
    { patientTvId },
    { campaignId },
    cancelToken
  );

const unenrollPatientFromCampaign = async ({
  patientTvId,
  patientCampaignId,
}: {
  patientTvId: PatientTvId,
  patientCampaignId: string,
}): Promise<
  ErrorResponse | DeleteResponse<'/providers/patients/:patientTvId/campaigns'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId/campaigns'>(
    '/providers/patients/:patientTvId/campaigns',
    { patientTvId },
    { patientCampaignId }
  );

const updateCampaignVisiblityOnPatientCreation = async ({
  campaignId,
  patientCreationVisibility,
}: {
  campaignId: string,
  patientCreationVisibility: boolean,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/campaigns/:campaignId/patientCreationVisibility'>
> =>
  apis.postT<'/providers/campaigns/:campaignId/patientCreationVisibility'>(
    '/providers/campaigns/:campaignId/patientCreationVisibility',
    { campaignId },
    { patientCreationVisibility }
  );

const createCampaign = async ({
  items,
  ...campaignData
}: {
  name: string,
  description: string,
  showOnPatientCreation: boolean,
  allowMultipleEnrollment: boolean,
  items: Array<CampaignItemConfigT>,
  autoEnrollGroup: ?CampaignAutoEnrollGroupT,
  ...CampaignTriggers,
}): Promise<ErrorResponse | PostResponse<'/providers/campaigns'>> =>
  apis.postT<'/providers/campaigns'>(
    '/providers/campaigns',
    {},
    {
      ...campaignData,
      items: items.map(({ data }) => data),
    }
  );

export const sendEmailAuthData = async (metadata: {
  email: string,
  clinicShortName: string,
}): Promise<ErrorResponse | PostResponse<'/patients/email/login/auth'>> =>
  apis.postT<'/patients/email/login/auth'>(
    '/patients/email/login/auth',
    {},
    metadata
  );

export const fetchEmailAuthData = async ({
  accessToken,
  cancelToken,
}: {|
  accessToken: string,
  cancelToken?: CancelToken,
|}): Promise<ErrorResponse | GetResponse<'/patients/email/auth'>> =>
  apis.getT<'/patients/email/auth'>(
    '/patients/email/auth',
    {},
    { accessToken },
    cancelToken
  );

export const sendMagicLinkEmail = async ({
  accessToken,
  cancelToken,
}: {|
  accessToken: string,
  cancelToken?: CancelToken,
|}): Promise<ErrorResponse | PostResponse<'/patients/email/auth'>> =>
  apis.postT<'/patients/email/auth'>(
    '/patients/email/auth',
    {},
    { accessToken },
    cancelToken
  );

const getClinicInstrumentsForAdmin = async ({
  clinicId,
  cancelToken,
}: {
  cancelToken?: CancelToken,
  clinicId: string,
}): Promise<
  ErrorResponse | GetResponse<'/admin/clinics/:clinicId/instruments'>
> =>
  apis.getT<'/admin/clinics/:clinicId/instruments'>(
    '/admin/clinics/:clinicId/instruments',
    { clinicId },
    {},
    cancelToken
  );

const getClinicsForAdmin = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/admin/clinics'>> =>
  apis.getT<'/admin/clinics'>('/admin/clinics', {}, {}, cancelToken);

const setEMRSettings = async ({
  clinicId,
  cancelToken,
  ...body
}: {
  clinicId: string,
  cancelToken?: CancelToken,
  type: 'redox',
  defaultClinicTimezone: string,
  verificationToken: string,
  validIdTypes: Array<string>,
}): Promise<ErrorResponse | PostResponse<'/admin/clinic/:clinicId/settings'>> =>
  apis.postT<'/admin/clinic/:clinicId/settings'>(
    '/admin/clinic/:clinicId/settings',
    { clinicId },
    body,
    cancelToken
  );

const fetchEMRSettings = async ({
  clinicId,
  cancelToken,
}: {
  clinicId: string,
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/admin/clinic/:clinicId/settings'>> =>
  apis.getT<'/admin/clinic/:clinicId/settings'>(
    '/admin/clinic/:clinicId/settings',
    { clinicId },
    {},
    cancelToken
  );

const setVideoSessionToken = async (data: {
  surveyJwtCode: ?string,
  userToken: string,
  patientSurveyId: PatientSurveyId,
  sessionToken: string,
}): Promise<ErrorResponse | PostResponse<'/users/video/token'>> =>
  apis.postT<'/users/video/token'>('/users/video/token', {}, data);

export const createPatientAttributes = async (body: {
  attributes: Array<{|
    name: string,
    type: 'number' | 'string' | 'boolean',
  |}>,
}): Promise<
  ErrorResponse | PostResponse<'/providers/clinic/attributes/create'>
> =>
  apis.postT<'/providers/clinic/attributes/create'>(
    '/providers/clinic/attributes/create',
    {},
    body
  );

export const fetchPatientAttributesForClinic = async ({
  cancelToken,
  onlyVisibleOnCreation,
}: {
  cancelToken?: CancelToken,
  onlyVisibleOnCreation?: boolean,
}): Promise<ErrorResponse | GetResponse<'/providers/clinic/attributes'>> =>
  apis.getT<'/providers/clinic/attributes'>(
    '/providers/clinic/attributes',
    {},
    { onlyVisibleOnCreation },
    cancelToken
  );

const updatePatientAttributesMetadata = async (payload: {
  ...$Diff<
    FetchedPatientAtttributeDataT,
    {
      reserved: any,
      id: any,
      name: any,
      dashboardTablePriority: any,
      dashboardPriority: any,
    }
  >,
  patientAttributeId: string,
}): Promise<ErrorResponse | PutResponse<'/clinicAdmin/clinic/attributes'>> =>
  apis.putT<'/clinicAdmin/clinic/attributes'>(
    '/clinicAdmin/clinic/attributes',
    {},
    payload
  );

const createPatientAttributesWithMetadata = async (
  payload: $Diff<
    FetchedPatientAtttributeDataT,
    {
      reserved: any,
      id: any,
      dashboardPriority: any,
      dashboardTablePriority: any,
    }
  >
): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinic/attributes'>> =>
  apis.postT<'/clinicAdmin/clinic/attributes'>(
    '/clinicAdmin/clinic/attributes',
    {},
    payload
  );

const updatePatientAttributeOrdering = async (
  payload: Array<{
    id: string,
    dashboardPriority: number,
  }>
): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/attributes/sort'>
> =>
  apis.postT<'/clinicAdmin/clinic/attributes/sort'>(
    '/clinicAdmin/clinic/attributes/sort',
    {},
    payload
  );

const updatePatientAttributeDashboardViewOrdering = async (
  payload: Array<{
    id: string,
    dashboardTablePriority: number,
  }>
): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/attributes/tableView/sort'>
> =>
  apis.postT<'/clinicAdmin/clinic/attributes/tableView/sort'>(
    '/clinicAdmin/clinic/attributes/tableView/sort',
    {},
    payload
  );

const fetchPatientAttributes = async ({
  patientTvId,
  cancelToken,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/:patientTvId/attributes'>
> =>
  apis.getT<'/providers/:patientTvId/attributes'>(
    '/providers/:patientTvId/attributes',
    { patientTvId },
    {},
    cancelToken
  );

const setPatientAttributes = async ({
  patientTvId,
  ...body
}: {
  patientTvId: PatientTvId,
  name: string,
  attributeValue: PatientAttributeValueT,
}): Promise<
  ErrorResponse | PostResponse<'/providers/:patientTvId/attributes'>
> =>
  apis.postT<'/providers/:patientTvId/attributes'>(
    '/providers/:patientTvId/attributes',
    { patientTvId },
    body
  );

const setDefaultFilters = async ({
  clinicStatusNames,
  name,
  expression,
  priorityOrdering,
  groupIds,
}: {
  clinicStatusNames: Array<string>,
  name: string,
  expression: string,
  priorityOrdering: PriorityOrderingT,
  groupIds: Array<string>,
}): Promise<ErrorResponse | PostResponse<'/providers/clinics/filters'>> =>
  apis.postT<'/providers/clinics/filters'>(
    '/providers/clinics/filters',
    {},
    { clinicStatusNames, groupIds, name, expression, priorityOrdering }
  );

const deleteDefaultFilters = async ({
  name,
}: {
  name: string,
}): Promise<ErrorResponse | PostResponse<'/providers/clinics/filters'>> =>
  apis.deleteT<'/providers/clinics/filters'>(
    '/providers/clinics/filters',
    {},
    { name }
  );

const getDefaultFilters = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/clinics/filters'>> =>
  apis.getT<'/providers/clinics/filters'>(
    '/providers/clinics/filters',
    {},
    {},
    cancelToken
  );

const getDefaultFiltersByProviderId = async ({
  providerTvId,
  cancelToken,
}: {
  providerTvId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/:providerTvId/defaultFilters'>
> =>
  apis.getT<'/clinicAdmin/:providerTvId/defaultFilters'>(
    '/clinicAdmin/:providerTvId/defaultFilters',
    { providerTvId },
    {},
    cancelToken
  );

const setDefaultFiltersByProviderId = async ({
  providerTvId,
  clinicStatusNames,
  name,
  expression,
  priorityOrdering,
  groupIds,
}: {
  providerTvId: string,
  clinicStatusNames: Array<string>,
  name: string,
  expression: string,
  priorityOrdering: PriorityOrderingT,
  groupIds: Array<string>,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/:providerTvId/defaultFilters'>
> =>
  apis.postT<'/clinicAdmin/:providerTvId/defaultFilters'>(
    '/clinicAdmin/:providerTvId/defaultFilters',
    { providerTvId },
    { clinicStatusNames, groupIds, name, expression, priorityOrdering }
  );

// qr code management
const getQRCodesInClinic = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/clinic/qrcode'>> =>
  apis.getT<'/clinicAdmin/clinic/qrcode'>(
    '/clinicAdmin/clinic/qrcode',
    {},
    {},
    cancelToken
  );

const createQRCode = async (body: {
  intakeFormInstrumentId: string,
  defaultPatientAttributes: PatientAttributeDefaultValues,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinic/qrcode'>> =>
  apis.postT<'/clinicAdmin/clinic/qrcode'>(
    '/clinicAdmin/clinic/qrcode',
    {},
    body
  );

const deleteQRCode = async ({
  qrCodeId,
}: {
  qrCodeId: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/clinic/qrcode'>> =>
  apis.deleteT<'/clinicAdmin/clinic/qrcode'>(
    '/clinicAdmin/clinic/qrcode',
    {},
    { qrCodeId }
  );

// phoneEnrollment management
const getPhoneEnrollmentItemsInClinic = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/phoneEnrollment'>
> =>
  apis.getT<'/clinicAdmin/clinic/phoneEnrollment'>(
    '/clinicAdmin/clinic/phoneEnrollment',
    {},
    {},
    cancelToken
  );

const createSystemPhoneNumber = async ({
  phoneNumber,
}: {
  phoneNumber: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/clinic/phoneNumbers'>> =>
  apis.postT<'/clinicAdmin/clinic/phoneNumbers'>(
    '/clinicAdmin/clinic/phoneNumbers',
    {},
    { phoneNumber }
  );

const getSystemPhoneNumberSummary = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/phoneNumbers/summary'>
> =>
  apis.getT<'/clinicAdmin/clinic/phoneNumbers/summary'>(
    '/clinicAdmin/clinic/phoneNumbers/summary',
    {},
    {},
    cancelToken
  );

const getSystemPhoneNumbers = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/clinicAdmin/clinic/phoneNumbers'>> =>
  apis.getT<'/clinicAdmin/clinic/phoneNumbers'>(
    '/clinicAdmin/clinic/phoneNumbers',
    {},
    {},
    cancelToken
  );

const createPhoneEnrollmentItem = async (body: {
  systemPhoneNumberId: string,
  defaultPatientAttributes: PatientAttributeDefaultValues,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/phoneEnrollment'>
> =>
  apis.postT<'/clinicAdmin/clinic/phoneEnrollment'>(
    '/clinicAdmin/clinic/phoneEnrollment',
    {},
    body
  );

const deletePhoneEnrollmentItem = async ({
  id,
}: {
  id: string,
}): Promise<
  ErrorResponse | DeleteResponse<'/clinicAdmin/clinic/phoneEnrollment'>
> =>
  apis.deleteT<'/clinicAdmin/clinic/phoneEnrollment'>(
    '/clinicAdmin/clinic/phoneEnrollment',
    {},
    { id }
  );

export const getCSVMapping = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/bulkUpload/csvMapping'>> =>
  apis.getT<'/providers/bulkUpload/csvMapping'>(
    '/providers/bulkUpload/csvMapping',
    {},
    {},
    cancelToken
  );

export const setCSVMapping = async ({
  cancelToken,
  mapping,
}: {
  cancelToken?: CancelToken,
  mapping: CSVMappingT,
}): Promise<ErrorResponse | PostResponse<'/providers/bulkUpload/csvMapping'>> =>
  apis.postT<'/providers/bulkUpload/csvMapping'>(
    '/providers/bulkUpload/csvMapping',
    {},
    { mapping },
    cancelToken
  );

export const enrollWithQRCode = async ({
  qrCodeId,
  clinicId,
  timeZone,
}: {
  qrCodeId: string,
  clinicId: string,
  timeZone: string,
}): Promise<ErrorResponse | PostResponse<'/users/enroll/qrcode'>> =>
  apis.postT<'/users/enroll/qrcode'>(
    '/users/enroll/qrcode',
    {},
    { qrCodeId, clinicId, timeZone }
  );

const connectProviderToCall = async ({
  queuedPhoneCallId,
  actionType,
}: {
  queuedPhoneCallId: string,
  actionType: 'send-call' | 'place-on-hold',
}): Promise<
  ErrorResponse | PostResponse<'/providers/patients/phoneCall/:id/actions'>
> =>
  apis.postT<'/providers/patients/phoneCall/:id/actions'>(
    '/providers/patients/phoneCall/:id/actions',
    { id: queuedPhoneCallId },
    { actionType }
  );

const getQueuedPhoneCalls = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/patients/phoneCalls'>> =>
  apis.getT<'/providers/patients/phoneCalls'>(
    '/providers/patients/phoneCalls',
    {},
    {},
    cancelToken
  );

const getQueuedPhoneCall = async ({
  queuedPhoneCallId,
  patientTvId,
  cancelToken,
}: {
  queuedPhoneCallId: string,
  patientTvId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/phoneCalls/:id'>
> =>
  apis.getT<'/providers/patients/phoneCalls/:id'>(
    '/providers/patients/phoneCalls/:id',
    { id: queuedPhoneCallId },
    { patientTvId },
    cancelToken
  );

const getIncomingCallHandlers = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/clinic/incomingCallHandler'>
> =>
  apis.getT<'/clinicAdmin/clinic/incomingCallHandler'>(
    '/clinicAdmin/clinic/incomingCallHandler',
    {},
    {},
    cancelToken
  );

const setIncomingCallHandlers = async ({
  incomingCallHandlers,
}: {
  incomingCallHandlers: Array<{|
    comparisionString: string,
    genericSurveyId: string,
    id: string,
  |}>,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/clinic/incomingCallHandler'>
> =>
  apis.postT<'/clinicAdmin/clinic/incomingCallHandler'>(
    '/clinicAdmin/clinic/incomingCallHandler',
    {},
    incomingCallHandlers
  );

const getAllMessagesInRelationship = async ({
  relationshipId,
  patientTvId,
  cancelToken,
}: {
  relationshipId: string,
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patients/:patientTvId/relationships/:relationshipId/messages'>
> =>
  apis.getT<'/providers/patients/:patientTvId/relationships/:relationshipId/messages'>(
    '/providers/patients/:patientTvId/relationships/:relationshipId/messages',
    { patientTvId, relationshipId },
    {},
    cancelToken
  );

const getPatientRelationshipMessageById = async ({
  cancelToken,
  patientTvId,
  patientRelationshipMessageId,
}: {
  patientTvId: PatientTvId,
  patientRelationshipMessageId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patients/:patientTvId/relationships/:patientRelationshipMessageId/item'>
> =>
  apis.getT<'/providers/patients/:patientTvId/relationships/:patientRelationshipMessageId/item'>(
    '/providers/patients/:patientTvId/relationships/:patientRelationshipMessageId/item',
    { patientTvId, patientRelationshipMessageId },
    {},
    cancelToken
  );

const getPatientRelationships = async ({
  cancelToken,
  patientTvId,
}: {
  patientTvId: PatientTvId,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/providers/patients/:patientTvId/relationships'>
> =>
  apis.getT<'/providers/patients/:patientTvId/relationships'>(
    '/providers/patients/:patientTvId/relationships',
    { patientTvId },
    {},
    cancelToken
  );

const assignMessageToRelationship = async ({
  messageId,
  sourcePatientTvId,
  targetPatientTvId,
  relationshipId,
}: {
  messageId: string,
  sourcePatientTvId: PatientTvId,
  targetPatientTvId: PatientTvId,
  relationshipId: string,
}): Promise<
  | ErrorResponse
  | PostResponse<'/providers/patients/:patientTvId/relationships/:messageId'>
> =>
  apis.postT<'/providers/patients/:patientTvId/relationships/:messageId'>(
    '/providers/patients/:patientTvId/relationships/:messageId',
    { patientTvId: sourcePatientTvId, messageId },
    {
      targetPatientTvId,
      relationshipId,
    }
  );

const getAssignedRelationshipsForMessage = async ({
  cancelToken,
  patientTvId,
  messageId,
}: {
  patientTvId: PatientTvId,
  messageId: string,
  cancelToken?: CancelToken,
}): Promise<
  | ErrorResponse
  | GetResponse<'/providers/patients/:patientTvId/relationships/:messageId'>
> =>
  apis.getT<'/providers/patients/:patientTvId/relationships/:messageId'>(
    '/providers/patients/:patientTvId/relationships/:messageId',
    { patientTvId, messageId },
    {},
    cancelToken
  );

const createPatientRelationships = async ({
  patientTvId,
  ...body
}: {
  patientTvId: PatientTvId,
  patientRelationshipType: string,
  otherPatientTvId: PatientTvId,
  otherPatientRelationshipType: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/patients/:patientTvId/relationships'>
> =>
  apis.putT<'/providers/patients/:patientTvId/relationships'>(
    '/providers/patients/:patientTvId/relationships',
    { patientTvId },
    body
  );

const deletePatientRelationships = async ({
  patientTvId,
  id,
}: {
  patientTvId: PatientTvId,
  id: string,
}): Promise<
  | ErrorResponse
  | DeleteResponse<'/providers/patients/:patientTvId/relationships'>
> =>
  apis.deleteT<'/providers/patients/:patientTvId/relationships'>(
    '/providers/patients/:patientTvId/relationships',
    { patientTvId },
    { id }
  );

const updatePatientRelationships = async ({
  patientTvId,
  id,
  firstPatientsRelationship,
  secondPatientsRelationship,
}: {
  patientTvId: PatientTvId,
  firstPatientsRelationship: string,
  secondPatientsRelationship: string,
  id: string,
}): Promise<
  ErrorResponse | PostResponse<'/providers/patients/:patientTvId/relationships'>
> =>
  apis.postT<'/providers/patients/:patientTvId/relationships'>(
    '/providers/patients/:patientTvId/relationships',
    { patientTvId },
    { id, firstPatientsRelationship, secondPatientsRelationship }
  );

const getMeiliAnalytics = async ({
  cancelToken,
}: {
  cancelToken?: CancelToken,
}): Promise<ErrorResponse | GetResponse<'/providers/analytics'>> =>
  apis.getT<'/providers/analytics'>(
    '/providers/analytics',
    {},
    {},
    cancelToken
  );

const deleteMeiliAnalytics = async ({
  id,
}: {
  id: string,
}): Promise<ErrorResponse | DeleteResponse<'/clinicAdmin/analytics'>> =>
  apis.deleteT<'/clinicAdmin/analytics'>('/clinicAdmin/analytics', {}, { id });

const updateMeiliAnalytics = async ({
  id,
  name,
}: {
  id: string,
  name: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/analytics'>> =>
  apis.postT<'/clinicAdmin/analytics'>(
    '/clinicAdmin/analytics',
    {},
    { id, name }
  );

const refreshMeiliAnalytics = async ({
  meiliAnalyticsId,
}: {
  meiliAnalyticsId: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/kibana/update/all'>> =>
  apis.postT<'/clinicAdmin/kibana/update/all'>(
    '/clinicAdmin/kibana/update/all',
    {},
    { meiliAnalyticsId }
  );

const createMeiliAnalytics = async ({
  name,
}: {
  name: string,
}): Promise<ErrorResponse | PostResponse<'/clinicAdmin/analytics'>> =>
  apis.putT<'/clinicAdmin/analytics'>('/clinicAdmin/analytics', {}, { name });

const getProviderAvailability = async ({
  providerTvId,
  cancelToken,
}: {
  providerTvId: string,
  cancelToken?: CancelToken,
}): Promise<
  ErrorResponse | GetResponse<'/clinicAdmin/:providerTvId/availability'>
> =>
  apis.getT<'/clinicAdmin/:providerTvId/availability'>(
    '/clinicAdmin/:providerTvId/availability',
    { providerTvId },
    {},
    cancelToken
  );

const setProviderAvailability = async ({
  providerTvId,
  days,
  timezone,
}: {
  days: ProviderAvailabilityDays,
  providerTvId: string,
  timezone: string,
}): Promise<
  ErrorResponse | PostResponse<'/clinicAdmin/:providerTvId/availability'>
> =>
  apis.postT<'/clinicAdmin/:providerTvId/availability'>(
    '/clinicAdmin/:providerTvId/availability',
    { providerTvId },
    { days, timezone }
  );

const server = {
  login,
  logout,
  isLoggedIn,
  extendSession,
  uploadImage,
  imageDownload,
  fetchSelectedPatientSurveys,
  sendPasswordResetEmail,
  resetPassword,
  changePassword,
  getSettings,
  setSettings,
  queryMedicines,
  submitAdhoc,
  setVideoSessionToken,
  createNewResponseForAdhoc,
  sendPatientMessage,
  createNewResponseForAdhocWithSurveyCode,
  uploadFileForPatient,
  getClinicMetadataFromShortName,
  joinVideo,
  getClinicFile,
  admin: {
    getClinicsForAdmin,
    getClinicInstrumentsForAdmin,
    toggleSupportRoleAsAdmin,
    setEMRSettings,
    fetchEMRSettings,
  },
  patient: {
    fetchSurveyWithCode,
    getPatientSurveyForPatient,
    fetchPatientSurveyForPatient,
    createYuUser,
    pendingSurveysJWT,
    joinVideoBySurveyCode,
    submitSurveyResponseWithCode,
    submitAdhocWithCode,
    refetchSurveyCode,
    fetchFreeformAppointmentMetadataForSurvey,
    fetchPatientAttributesForSurvey,
    validateSurveyInput,
    vitalAPIConnect,
    uploadTranscriptionForClinic,
  },
  provider: {
    getPushDevices,
    addPushDevice,
    removePushDevice,
    queryDataSource,
    fetchPatientAttributeValues,
    addPatientToGroupAsProvider,
    deletePatientToGroupAsProvider,
    trainChatbot,
    fetchChatbotConfig,
    fetchProvidersInClinic,
    fetchChatbotMessages,
    testResponse,
    testSendChatbotMessage,
    changePatientUnreadStatus,
    setChatbotStatus,
    endVideoSession,
    getPatientSurveys,
    createReferral,
    uploadFileForClinic,
    updateProviderLanguage,
    uploadFile,
    deleteSurvey,
    postConsent,
    createVideoCall,
    startVideo,
    fetchVideoLogs,
    getPatientSurvey,
    deletePatientSurvey,
    fetchMessages,
    sendMessage,
    getPatientCareTeam,
    confirmNursePhoneNumber,
    setNursePhoneNumber,
    getNursePhoneNumber,
    getNurseHours,
    updateNurseHours,
    getGroups,
    dismissAlert,
    fetchResponseById,
    fetchCSVResponses,
    fetchResponses,
    fetchInstruments,
    fetchPatientById,
    sendSurveyMessage,
    fetchPatients,
    fetchPatientList,
    queryPatientsFromCache,
    fetchPatientDetails,
    fetchPatientFromCache,
    updatePatientInCache,
    fetchSurveyFormatWithPatientSurvey,
    fetchSurveyFormat,
    createSurveyResponseForPatient,
    fetchPrefilledSurveys,
    fetchPrefilledSurveyById,
    getProviderMetadata,
    sendAdhocPatientSurvey,
    getProviderCareTeam,
    addPatientToInstrument,
    fetchPatientsForGroup,
    getNurseNotificationType,
    updateNurseNotificationType,
    setDefaultGroup,
    removePatientFromInstrument,
    deprecateRecurringSurvey,
    fetchAppointments,
    createPatientAccountFromProvider,
    bulkCreatePatients,
    getProviderAppointments,
    getAppointmentTags,
    getAppointment,
    getPatientActionMetadata,
    fetchInstrumentOverview,
    fetchThreads,
    createThreads,
    searchMessages,
    updatePatient,
    resendErroredMessage,
    addPatientStatus,
    deletePatientStatus,
    getPatientSurveyReminders,
    resolveStatusForSurvey,
    updateCreatePatientSurveyReminders,
    deleteAppointment,
    deletePatientSurveyReminders,
    createAppointments,
    fetchAllReferrals,
    completeReferral,
    fetchReferral,
    getCampaigns,
    deprecateCampaign,
    updateCampaignVisiblityOnPatientCreation,
    getCampaignItemsForCampaign,
    getPatientsEnrolledInCampaign,
    getEnrolledCampaignsForPatient,
    enrollPatientInCampaign,
    unenrollPatientFromCampaign,
    createCampaign,
    getAllExercises,
    createExercise,
    deleteExercise,
    fetchAllExerciseTags,
    createExerciseTag,
    searchExercises,
    getExerciseById,
    deprecatePatient,
    setDefaultEnrolled,
    sendDefaultEnrolledInstrumentToPatient,
    sendDefaultEnrolledInstrumentToPatientNow,
    fetchPatientAttributes,
    setPatientAttributes,
    getQueuedAppointments,
    getAllGroupsInClinic,
    setDefaultFilters,
    getFilterableClinicStatus,
    getDefaultFilters,
    deleteDefaultFilters,
    csvMapping: {
      getCSVMapping,
      setCSVMapping,
    },
    queuedPhoneCalls: {
      getQueuedPhoneCalls,
      getQueuedPhoneCall,
      connectProviderToCall,
    },
    patientRelationships: {
      getPatientRelationships,
      deletePatientRelationships,
      updatePatientRelationships,
      createPatientRelationships,
      // patient relationship messages
      getAssignedRelationshipsForMessage,
      getPatientRelationshipMessageById,
      assignMessageToRelationship,
      getAllMessagesInRelationship,
    },
    fetchDataSources,
    setSurveyTags,
    getMeiliAnalytics,
  },
  clinicAdmin: {
    getSystemPhoneNumberCallQueue,
    saveSystemPhoneNumberCallQueue,
    getClinicAPISettings,
    setClinicAPISettings,
    getProviderTags,
    createProviderTag,
    markAllMessagesAsRead,
    deleteProviderTag,
    assignProviderTagForProvider,
    deleteProviderTagForProvider,
    updateInstrumentData,
    getClinicAdminCareTeam,
    updateGroupName,
    toggleSupportRole,
    deleteUserFromGroup,
    addUserToGroup,
    createGroup,
    enablePatient,
    setUserGroups,
    createProviderForClinicAdmin,
    deleteAppointmentTag,
    createAppointmentTag,
    getClinicUsers,
    fetchAuditLogs,
    getConsentMessage,
    postConsentMessage,
    getClinicSettings,
    updateProvider,
    setClinicSettings,
    createClinicStatus,
    getGlobalClinicStatus,
    deleteClinicStatus,
    fetchGlobalCSVResponses,
    fetchCalendarLink,
    updatePatientAttributesMetadata,
    createPatientAttributesWithMetadata,
    updatePatientAttributeOrdering,
    updatePatientAttributeDashboardViewOrdering,
    toggleGroupNotifications,
    deleteQRCode,
    createQRCode,
    getQRCodesInClinic,
    // phone enrollment item
    deletePhoneEnrollmentItem,
    createPhoneEnrollmentItem,
    getPhoneEnrollmentItemsInClinic,
    // default instruments
    getDefaultInstrumentsForClinicAdmin,
    deleteDefaultInstruments,
    addDefaultInstruments,
    // incoming call handlers
    setIncomingCallHandlers,
    getIncomingCallHandlers,
    // vital connections
    getVitalConnectors,
    addVitalConnectors,
    deleteVitalConnectors,
    // system phone numbers
    createSystemPhoneNumber,
    getSystemPhoneNumbers,
    getSystemPhoneNumberSummary,
    // data source management
    updateDataItemsForDataSource,
    fetchDataSourceById,
    configureDataSource,
    // meili analytics
    deleteMeiliAnalytics,
    createMeiliAnalytics,
    updateMeiliAnalytics,
    refreshMeiliAnalytics,
    // provider availability
    getProviderAvailability,
    setProviderAvailability,
    getDefaultFiltersByProviderId,
    setDefaultFiltersByProviderId,
  },
  users: {
    fetchResponseWithOneTimeAccessShortCode,
  },
  updateCancerType,
  savePushNotificationToken,
  createSurvey,
  fetchSurveyCodeForShortLink,
  validateAuthCode,
  getInstrumentFileForSurvey,
  getMetadata,
  getExercisesForSurvey,
};

export default server;
